import { createAction } from "redux-actions";
import * as actionTypes from "@cv-store/action-types";

const setFormData = createAction(actionTypes.SET_FORM_DATA);

const setFieldErrors = createAction(actionTypes.SET_FIELDS_ERRORS);

const setDraftValues = createAction(actionTypes.SET_DRAFT_VALUES);

const setTranslates = createAction(actionTypes.SET_TRANSLATES);

const _fieldValueChanged = createAction(
    actionTypes.FIELD_VALUE_CHANGED,
    (fieldType, newValue) => ({ type: fieldType, value: newValue })
);

const fieldValueChanged = (fieldType, newValue) => (dispatch, getState) => {
    const state = getState();
    let fieldValue = state.getIn(["formData", "fields", fieldType, "value"]);
    dispatch(_fieldValueChanged(fieldType, newValue(fieldValue)));
};

export { fieldValueChanged, setFormData, setFieldErrors, setDraftValues, setTranslates };