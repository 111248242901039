import React from "react";
import PropTypes from "prop-types";
import CvField from "@cv-store/models/CvField";
import BaseSimpleTextField from "@cv-components/widgets/BaseSimpleTextField";
import TextareaInput from "@cv-components/widgets/form/TextareaInput";

const SimpleTextareaField = ({ field, errors }) => {
    return (
        <BaseSimpleTextField
            field={field}
            errors={errors}
            renderComponent={(value, onChange, placeholder) => (
                <TextareaInput
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                />
            )}
        />
    );
};

SimpleTextareaField.propTypes = {
    field: PropTypes.instanceOf(CvField).isRequired,
    errors: PropTypes.object.isRequired,
};

export default React.memo(SimpleTextareaField);