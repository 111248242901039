import React, { useCallback } from "react";
import { Map } from "immutable";
import SimpleMultiItemsField from "@cv-components/field/SimpleMultiItemsField";
import PublicationItem from "@cv-components/field/AdditionalInformationPublications/PublicationItem";

const defaultItem = {
    title: "",
    year: "",
    additionalInfo: "",
};

const AdditionalInformationPublications = ({ field, errors }) => {
    const translates = field.translates;

    const renderItems = useCallback((items, handleRemoveItem, handleItemChange, handleAddItem) => {
        return items.map((item, index) => {
            return (
                <PublicationItem
                    key={item.get("id")}
                    item={item}
                    handleRemove={handleRemoveItem}
                    handleAddItem={handleAddItem}
                    handleChange={handleItemChange}

                    showAddBtn={items.size === index+1}

                    translates={translates}
                    errors={errors.getIn(["items", index.toString()]) || Map({})}
                />
            );
        });
    }, [errors, translates]);

    return (
        <SimpleMultiItemsField
            defaultItem={defaultItem}
            errors={errors}
            field={field}
            renderItems={renderItems}
        />
    );
};

export default AdditionalInformationPublications;