import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import getImagePreviewFromFile from "../../../../../utils/getImagePreviewFromFile";
import uploadImage from "@cv/utils/uploadImage";
import { Map } from "immutable";
import "react-image-crop/lib/ReactCrop.scss";
import ImgPreview from "@cv-components/widgets/form/FileInput/ImagePreview";
import CropModal from "@cv-components/widgets/form/FileInput/CropModal";
import uid from "uid";

const ImageInput = ({ imageId, imageUrl, onChange, btnText, cropperSelectBtnText, acceptedTypes, uploadUrl }) => {
    const [id, setId] = useState(null);
    const [file, setFile] = useState(Map({
        fileId: imageId,
        fileName: imageUrl.split("/").pop(),
        imageUrl: imageUrl,
        fileSelected: null
    }));
    const [showPreview, setShowPreview] = useState(imageUrl);
    const [showCrop, setShowCrop] = useState(false);

    useEffect(() => {
        setId(uid());
    }, [setId]);

    const handleFileSelectedChange = useCallback((e) => {
        setFile(null);

        const fileSelected = e.target.files[0];

        let newFileData = file
            .set("fileName", fileSelected.name)
            .set("fileSelected", fileSelected);

        setFile(newFileData);
        setShowPreview(true);

        getImagePreviewFromFile(fileSelected)
            .then((dataUrl) => {
                setFile(newFileData.set("imageUrl", dataUrl));
            });

        setShowCrop(true);
    }, [setShowPreview, file, setFile, setShowCrop]);

    const handleRemoveImgClick = useCallback(() => {
        setFile(Map({}));
        onChange(null);
        setShowPreview(false);
    }, [onChange, setShowPreview]);

    const handleAreaSelected = useCallback((sizes) => {
        sizes = (sizes.x || sizes.y || sizes.x1 || sizes.y1) ? sizes : null;

        uploadImage(uploadUrl, file.get("fileSelected"), sizes)
            .then((result) => {
                setFile(file.set("fileId", result.entityImageId).set("imageUrl", result.url));
                onChange(result.entityImageId);
            });
    }, [uploadUrl, file, setFile, onChange]);

    const hidePreview = useCallback(() => setShowCrop(false), [setShowCrop]);

    return (
        <div>
            <div className="d-flex">
                <input
                    className="file-name"
                    type="text"
                    readOnly={true}
                    value={file.get("fileName") || ""}
                />
                <input
                    className="form-control"
                    id={id}
                    type="file"
                    onChange={handleFileSelectedChange}
                    style={{ display: "none" }}
                    accept={acceptedTypes.join(", ")}
                    multiple={false}
                />
                <label htmlFor={id} className="btn btn-info btn-file">
                    {btnText}
                </label>
            </div>
            {showPreview ? (
                <div className="image-cv-container">
                    <ImgPreview
                        loading={!file.get("fileId")}
                        imagePreview={file.get("imageUrl")}
                        onDeleteClick={handleRemoveImgClick}
                    />
                </div>
            ) : null}
            {file.get("imageUrl") && showCrop ? (
                <CropModal
                    imageUrl={file.get("imageUrl")}
                    onCropped={handleAreaSelected}
                    btnText={cropperSelectBtnText}
                    onReject={handleRemoveImgClick}
                    closeModal={hidePreview}
                />
            ) : null}
        </div>
    );
};

ImageInput.defaultProps = {
    acceptedTypes: ["image/jpeg", "image/gif", "image/png"],
    imageUrl: ""
};

ImageInput.propTypes = {
    imageId: PropTypes.number,
    imageUrl: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    uploadUrl: PropTypes.string.isRequired,
    btnText: PropTypes.string.isRequired,
    cropperSelectBtnText: PropTypes.string.isRequired,
    acceptedTypes: PropTypes.array,
};

export default ImageInput;
