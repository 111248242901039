import React, { useCallback } from "react";
import PropTypes from "prop-types";
import useGetOnChange from "@cv/hooks/useGetOnChange";
import TextInput from "@cv-components/widgets/form/TextInput";
import AddItemBtn from "@cv-components/widgets/RemoveItemBtn/AddItemBtn";
import ControlButtonsContainer from "@cv-components/widgets/RemoveItemBtn/ControlButtonsContainer";
import RemoveItemBtn from "@cv-components/widgets/RemoveItemBtn/RemoveItemBtn";
import SimpleField from "@cv-components/widgets/form/SimpleField";

const RecommendationItem = ({ item, translates, errors, showAddBtn, handleRemove, handleChange, handleAddItem }) => {
    const onRemove = useCallback(() => handleRemove(item.get("id")), [handleRemove, item]);
    const getOnChange = useGetOnChange(handleChange, item);

    const onNameChange = getOnChange("recName");
    const onPositionChange = getOnChange("recPosition");
    const onPhoneChange = getOnChange("recPhone");
    const onEmailChange = getOnChange("recEmail");

    return (
        <div className="recommendation-items">
            <SimpleField fieldName="recName" translates={translates} errors={errors} isRequired={true}>
                <TextInput
                    onChange={onNameChange}
                    value={item.get("recName")}
                    placeholder={translates.get("recNamePlaceholder")}
                />
            </SimpleField>
            <SimpleField fieldName="recPosition" translates={translates} errors={errors} isRequired={true}>
                <TextInput
                    onChange={onPositionChange}
                    value={item.get("recPosition")}
                    placeholder={translates.get("recPositionPlaceholder")}
                />
            </SimpleField>
            <SimpleField fieldName="recPhone" translates={translates} errors={errors}>
                <TextInput
                    onChange={onPhoneChange}
                    value={item.get("recPhone")}
                    placeholder={translates.get("recPhonePlaceholder")}
                    type="tel"
                />
            </SimpleField>
            <SimpleField fieldName="recEmail" translates={translates} errors={errors}>
                <TextInput
                    onChange={onEmailChange}
                    value={item.get("recEmail")}
                    placeholder={translates.get("recEmailPlaceholder")}
                    type="email"
                />
            </SimpleField>
            <ControlButtonsContainer addClass="justify-content-between d-flex">
                <RemoveItemBtn onClick={onRemove}>{translates.get("removeRecommendationItemBtnText")}</RemoveItemBtn>
                {showAddBtn ? (
                    <AddItemBtn onClick={handleAddItem}>{translates.get("addRecommendationBtnText")}</AddItemBtn>
                ) : null}
            </ControlButtonsContainer>
        </div>
    );
};

RecommendationItem.propTypes = {
    item: PropTypes.object.isRequired,
    translates: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    showAddBtn: PropTypes.bool,
    handleRemove: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleAddItem: PropTypes.func.isRequired,
};

export default React.memo(RecommendationItem);