import React from "react";
import useFieldChange from "@cv/hooks/useFieldChange";
import Container from "@cv-components/widgets/Container/Container";
import FormDelimiter from "@cv-components/widgets/FormDelimiter/FormDelimiter";
import SimpleField from "@cv-components/widgets/form/SimpleField";
import TextInput from "@cv-components/widgets/form/TextInput";
import TextareaInput from "@cv-components/widgets/form/TextareaInput";

const FutureWorkRequirementsInformationAboutSalary = ({ field, errors }) => {
    const monthlyMinValueChange = useFieldChange(field, (inputVal, value) => value.set("monthlyMinValue", inputVal));
    const hourlyMinValueChange = useFieldChange(field, (inputVal, value) => value.set("hourlyMinValue", inputVal));
    const additionalInfoChange = useFieldChange(field, (inputVal, value) => value.set("additionalInfo", inputVal));
    const translates = field.translates;
    const value = field.value;

    return (
        <Container addClass="border-none">
            <FormDelimiter>{translates.get("title")}</FormDelimiter>
            <SimpleField fieldName="monthlyMinValue" errors={errors} translates={translates}>
                <TextInput
                    value={value.get("monthlyMinValue")}
                    onChange={monthlyMinValueChange}
                    placeholder={translates.get("monthlyMinValuePlaceholder")}
                    isNumeric={true}
                    signAfterPoint={2}
                />
            </SimpleField>
            <SimpleField fieldName="hourlyMinValue" errors={errors} translates={translates}>
                <TextInput
                    value={value.get("hourlyMinValue")}
                    onChange={hourlyMinValueChange}
                    placeholder={translates.get("hourlyMinValuePlaceholder")}
                    isNumeric={true}
                    signAfterPoint={2}
                />
            </SimpleField>
            <SimpleField fieldName="additionalInfo" errors={errors} translates={translates}>
                <TextareaInput
                    value={value.get("additionalInfo")}
                    onChange={additionalInfoChange}
                    placeholder={translates.get("additionalInfoPlaceholder")}
                />
            </SimpleField>
        </Container>
    );
};

export default FutureWorkRequirementsInformationAboutSalary;