import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import SimpleField from "@cv-components/widgets/form/SimpleField";
import DropDown from "@cv-components/widgets/form/DropDown";
import useGetOnChange from "@cv/hooks/useGetOnChange";
import RemoveItemBtn from "@cv-components/widgets/RemoveItemBtn/RemoveItemBtn";
import AddItemBtn from "@cv-components/widgets/RemoveItemBtn/AddItemBtn";
import ControlButtonsContainer from "@cv-components/widgets/RemoveItemBtn/ControlButtonsContainer";
import TextInput from "@cv-components/widgets/form/TextInput";

const CustomSkill = ({
    customSkillItem,
    handleChange,
    handleAddItem,
    handleRemove,
    translates,
    errors,
    config,
    showAddBtn
}) => {
    const itemId = customSkillItem.get("id");
    const onRemove = useCallback(() => handleRemove(itemId), [handleRemove, itemId]);
    const getOnChange = useGetOnChange(handleChange, customSkillItem);

    const onSkillNameChange = getOnChange("skillName");
    const onSkillEvaluationChange = getOnChange("skillEvaluation");

    const evaluationOptions = config.get("evaluationOptions");

    useEffect(() => {
        onSkillEvaluationChange(evaluationOptions[0].value);
    }, []);// eslint-disable-line

    return (
        <div>
            <SimpleField fieldName="skillName" errors={errors} translates={translates} isRequired={true}>
                <TextInput
                    value={customSkillItem.get("skillName")}
                    onChange={onSkillNameChange}
                    placeholder={translates.get("skillNamePlaceholder")}
                />
            </SimpleField>
            <SimpleField fieldName="skillEvaluation" errors={errors} translates={translates}>
                <DropDown
                    value={customSkillItem.get("skillEvaluation")}
                    options={evaluationOptions}
                    onChange={onSkillEvaluationChange}
                />
            </SimpleField>
            <ControlButtonsContainer addClass="justify-content-between d-flex">
                <RemoveItemBtn onClick={onRemove}>{translates.get("removeItemBtnText")}</RemoveItemBtn>
                {showAddBtn ? (
                    <AddItemBtn onClick={handleAddItem}>{translates.get("addBtnText")}</AddItemBtn>
                ) : null}
            </ControlButtonsContainer>
        </div>
    );
};

CustomSkill.propTypes = {
    customSkillItem: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleAddItem: PropTypes.func.isRequired,
    handleRemove: PropTypes.func.isRequired,
    translates: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    showAddBtn: PropTypes.bool.isRequired,
};

export default CustomSkill;