import React from "react";
import PropTypes from "prop-types";
import SelectOptionsListItem from "./SelectOptionsListItem";

const SEARCH_INPUT_HEIGHT = 30;

const SelectOptionsList = ({ maxHeight, withSearch, search, handleSearch, options, value, handleSelect }) => {
    const listHeight = withSearch ? maxHeight - SEARCH_INPUT_HEIGHT : maxHeight;

    return (
        <div style={{ maxHeight: maxHeight }}>
            {withSearch ? (
                <div className="search-input-container">
                    <input
                        type="text"
                        value={search || ""}
                        onChange={handleSearch}
                    />
                </div>
            ) : null}
            <ul
                className={"items-container"}
                style={{ maxHeight: listHeight }}
            >

                {options.map((option) => (
                    <SelectOptionsListItem
                        key={option.value}
                        option={option}
                        checked={value ? option.value === value.value : false}
                        onClick={handleSelect}
                    />
                ))}
            </ul>
        </div>
    );
};

SelectOptionsList.propTypes = {
    maxHeight: PropTypes.number.isRequired,
    withSearch: PropTypes.bool,
    search: PropTypes.string,
    handleSearch: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    valueOption: PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    handleSelect: PropTypes.func.isRequired
};

export default SelectOptionsList;