import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import FieldLayout from "@cv-components/widgets/FieldLayout/FieldLayout";
import FormGroup from "@cv-components/widgets/form/FormGroup";
import DropDown from "@cv-components/widgets/form/DropDown";
import getAxiosClient from "@cv/utils/getAxiosClient";
import _ from "lodash";

const adminUnitsToSelectFormat = (adminUnitsMap) => {
    return _.map(adminUnitsMap, function(label, value) {
        return { label, value: +value };
    });
};

const LocationWidget = ({ item, errors, additionalErrors, config, translates, isRequired, getOnChange }) => {
    const [childrenAdminUnits, setChildrenAdminUnits] = useState(config.get("childrenAdminUnits"));

    const countryChange = getOnChange((inputVal, value) => {
        const newCountryId = +inputVal;
        let newValue = value.set("countryId", newCountryId);
        if (newCountryId !== config.get("countryHasAdminUnits")) {
            newValue = newValue
                .set("parentAdminUnitId", null)
                .set("adminUnitId", null);
        }

        return newValue;
    });

    const parentAdminUnitIdChange = getOnChange((parentAdminUnitId, value) => {
        return value
            .set("parentAdminUnitId", +parentAdminUnitId)
            .set("parentAdminUnitName", config.get("mainAdminUnits").find((unit) => unit.value === +parentAdminUnitId)?.label);
    });

    const adminUnitIdChange = getOnChange((adminUnitId, value) => {
        return value
            .set("adminUnitId", +adminUnitId)
            .set("adminUnitName", childrenAdminUnits.find((unit) => unit.value === +adminUnitId)?.label);
    });

    const getAdminUnits = useCallback((parentId) => {
        const url = config.get("getAdminUnitsUrl")+parentId;
        return getAxiosClient().get(url);
    }, [config]);

    useEffect(() => {
        if (item.get("parentAdminUnitId")) {
            getAdminUnits(item.get("parentAdminUnitId"))
                .then((response) => {
                    if (response.status === 200) {
                        setChildrenAdminUnits(adminUnitsToSelectFormat(response.data));
                    }
                });
        }
    }, [item, getAdminUnits]);

    const handleAdminUnitChange = useCallback((parentId) => {
        setChildrenAdminUnits(null);
        getAdminUnits(parentId)
            .then((response) => {
                if (response.status === 200) {
                    parentAdminUnitIdChange(parentId);
                    setChildrenAdminUnits(adminUnitsToSelectFormat(response.data));
                }
            });
    }, [parentAdminUnitIdChange, setChildrenAdminUnits, getAdminUnits]);

    let allErrors = [];
    if (errors.get("countryId")) {
        allErrors = allErrors.concat(errors.get("countryId"));
    }

    if (errors.get("adminUnitId")) {
        allErrors = allErrors.concat(errors.get("adminUnitId"));
    }

    if (additionalErrors) {
        allErrors = allErrors.concat(additionalErrors);
    }

    return (
        <FieldLayout
            label={translates.get("label")}
            helpText={translates.get("helpText")}
            isRequired={isRequired}
            hasError={!!allErrors.length}
        >
            <FormGroup error={allErrors}>
                <div className="location-select">
                    <DropDown
                        value={item.get("countryId")}
                        onChange={countryChange}
                        options={config.get("countries")}
                    />
                    {item.get("countryId") === config.get("countryHasAdminUnits") ? (
                        <>
                            <DropDown
                                value={+item.get("parentAdminUnitId")}
                                onChange={handleAdminUnitChange}
                                options={config.get("mainAdminUnits")}
                            />
                            {item.get("parentAdminUnitId") && childrenAdminUnits ? (
                                <DropDown
                                    value={+item.get("adminUnitId")}
                                    onChange={adminUnitIdChange}
                                    options={childrenAdminUnits}
                                />
                            ) : null}
                        </>
                    ) : null}
                </div>
            </FormGroup>
        </FieldLayout>
    );
};

LocationWidget.propTypes = {
    item: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    translates: PropTypes.object.isRequired,
    isRequired: PropTypes.bool.isRequired,
    getOnChange: PropTypes.func.isRequired,
};

export default LocationWidget;