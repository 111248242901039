import React, { useCallback } from "react";
import PropTypes from "prop-types";
import SimpleField from "@cv-components/widgets/form/SimpleField";
import useGetOnChange from "@cv/hooks/useGetOnChange";
import RemoveItemBtn from "@cv-components/widgets/RemoveItemBtn/RemoveItemBtn";
import AddItemBtn from "@cv-components/widgets/RemoveItemBtn/AddItemBtn";
import ControlButtonsContainer from "@cv-components/widgets/RemoveItemBtn/ControlButtonsContainer";
import TextInput from "@cv-components/widgets/form/TextInput";
import TextareaInput from "@cv-components/widgets/form/TextareaInput";

const OrganizationItem = ({
    item,
    handleChange,
    handleAddItem,
    handleRemove,
    translates,
    errors,
    showAddBtn
}) => {
    const itemId = item.get("id");
    const onRemove = useCallback(() => handleRemove(itemId), [handleRemove, itemId]);
    const getOnChange = useGetOnChange(handleChange, item);

    const onTitleChange = getOnChange("title");
    const onAdditionalInfoChange = getOnChange("additionalInfo");

    return (
        <div>
            <SimpleField fieldName="title" errors={errors} translates={translates} isRequired={true}>
                <TextInput
                    value={item.get("title")}
                    onChange={onTitleChange}
                    placeholder={translates.get("titlePlaceholder")}
                />
            </SimpleField>
            <SimpleField fieldName="additionalInfo" errors={errors} translates={translates}>
                <TextareaInput
                    value={item.get("additionalInfo")}
                    onChange={onAdditionalInfoChange}
                    placeholder={translates.get("additionalInfoPlaceholder")}
                />
            </SimpleField>
            <ControlButtonsContainer>
                <RemoveItemBtn onClick={onRemove}>{translates.get("removeItemBtnText")}</RemoveItemBtn>
                {showAddBtn ? (
                    <AddItemBtn onClick={handleAddItem}>{translates.get("addBtnText")}</AddItemBtn>
                ) : null}
            </ControlButtonsContainer>
        </div>
    );
};

OrganizationItem.propTypes = {
    item: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleAddItem: PropTypes.func.isRequired,
    handleRemove: PropTypes.func.isRequired,
    translates: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    showAddBtn: PropTypes.bool.isRequired,
};

export default OrganizationItem;