import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "../../../../widgets/Select/Select";

const AsyncDropDown = ({ placeholder, value, onChange, disabled, load }) => {
    let defaultOptions = [];
    if (value) {
        defaultOptions = [value];
    }

    if (placeholder) {
        defaultOptions = [{
            label: placeholder,
            value: ""
        }];
    }

    const [options, setOptions] = useState(defaultOptions);

    useEffect(() => {
        load().then((res) => {
            setOptions(res);
        });
    }, [load]);

    return (
        <Select
            value={value}
            cacheOptions
            options={options}
            loadOptions={load}
            onChange={onChange}
            isDisabled={disabled}
            isSearchable={options.length > 5}
            placeholder={placeholder}
        />
    );
};

AsyncDropDown.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    load: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string
};

const DropDown = ({ placeholder, value, options, onChange, disabled = false, name, debug = 0 }) => {
    const handleChange =  useCallback((selectedOption) => {
        onChange(selectedOption.value);
    }, [onChange]);
    const resVal = (value?.toString()) ? options.find((option) => option.value.toString() === value?.toString()) : value;

    return (
        <Select
            value={resVal}
            onChange={handleChange}
            options={options}
            isDisabled={disabled}
            isLoading={false}
            isSearchable={options.length > 5}
            placeholder={placeholder}
            debug={debug}
            name={name}
        />
    );
};

DropDown.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string
};

export { DropDown as default, AsyncDropDown };