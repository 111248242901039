import React from "react";
import PropTypes from "prop-types";
import CvField from "@cv-store/models/CvField";
import useFieldChange from "@cv/hooks/useFieldChange";
import { Map } from "immutable";
import EducationItem from "@cv-components/field/UserEducationEducation/items/EducationItem";
import useMultiItems from "@cv/hooks/useMultiItems";
import AddItemBtn from "@cv-components/widgets/RemoveItemBtn/AddItemBtn";
import ControlButtonsContainer from "@cv-components/widgets/RemoveItemBtn/ControlButtonsContainer";

export const LEVEL_PRIMARY = "primary";
export const LEVEL_SECONDARY = "secondary";
export const LEVEL_VOCATIONAL = "vocational";
export const LEVEL_HIGHER = "higher";
export const LEVEL_ADDITIONAL = "additional";

const defaultItem = { educationLevel: null };

const UserEducationEducation = ({ field, errors }) => {
    const canAddItem = (items) => items.findIndex((item) => item.get("educationLevel") === null) === -1;

    const { addItem, removeItem, changeItem } = useMultiItems(canAddItem, defaultItem);
    const isAddItemDisabled = !canAddItem(field.value.get("items"));

    const handleAddItem = useFieldChange(field, addItem);
    const handleRemoveItem = useFieldChange(field, removeItem);
    const handleItemChange = useFieldChange(field, changeItem);

    return (
        <>
            <div>
                {field.value.get("items").map((item, index) => (
                    <EducationItem
                        key={item.get("id")}
                        item={item}
                        handleRemove={handleRemoveItem}
                        handleChange={handleItemChange}
                        translates={field.translates}
                        config={field.config}
                        errors={errors.getIn(["items", index.toString()]) || Map({})}
                    />
                ))}
            </div>
            <ControlButtonsContainer>
                <AddItemBtn onClick={handleAddItem} disabled={isAddItemDisabled}>
                    {field.translates.get("addEducationBtnText")}
                </AddItemBtn>
            </ControlButtonsContainer>
        </>
    );
};

UserEducationEducation.propTypes = {
    field: PropTypes.instanceOf(CvField).isRequired,
    errors: PropTypes.object.isRequired,
};

export default React.memo(UserEducationEducation);