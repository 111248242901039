import React from "react";
import PropTypes from "prop-types";
import CvField from "@cv-store/models/CvField";
import useFieldChange from "@cv/hooks/useFieldChange";
import DropDown from "@cv-components/widgets/form/DropDown";
import RadioList from "@cv-components/widgets/form/RadioList";
import SimpleField from "@cv-components/widgets/form/SimpleField";

const UserInfoGender = ({ field, errors }) => {
    const genderChange = useFieldChange(field, (selectedGender, value) => value.set("gender", selectedGender));
    const isHiddenChange = useFieldChange(field, (isHidden, value) => value.set("isHidden", +isHidden));

    return (
        <>
            <SimpleField translates={field.translates} errors={errors} fieldName="gender" isRequired={true}>
                <DropDown
                    name="gender"
                    value={+field.value.get("gender")}
                    options={field.config.get("genderOptions")}
                    onChange={genderChange}
                    placeholder={field.translates.get("genderPlaceholder")}
                />
            </SimpleField>
            <SimpleField translates={field.translates} errors={errors} fieldName="isHidden">
                <RadioList
                    name="isHidden"
                    options={field.config.get("isHiddenOptions")}
                    value={field.value.get("isHidden")}
                    onChange={isHiddenChange}
                />
            </SimpleField>
        </>
    );
};

UserInfoGender.propTypes = {
    field: PropTypes.instanceOf(CvField).isRequired,
    errors: PropTypes.object.isRequired,
};

export default React.memo(UserInfoGender);