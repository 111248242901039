import React, { useCallback } from "react";
import PropTypes from "prop-types";
import SimpleField from "@cv-components/widgets/form/SimpleField";
import useGetOnChange from "@cv/hooks/useGetOnChange";
import RemoveItemBtn from "@cv-components/widgets/RemoveItemBtn/RemoveItemBtn";
import AddItemBtn from "@cv-components/widgets/RemoveItemBtn/AddItemBtn";
import ControlButtonsContainer from "@cv-components/widgets/RemoveItemBtn/ControlButtonsContainer";
import TextInput from "@cv-components/widgets/form/TextInput";
import TextareaInput from "@cv-components/widgets/form/TextareaInput";
import useYearInputValidation from "@cv/hooks/useYearInputValidation";

const CertificateOrLicenseItem = ({
    customSkillItem,
    handleChange,
    handleAddItem,
    handleRemove,
    translates,
    errors,
    showAddBtn
}) => {
    const itemId = customSkillItem.get("id");
    const onRemove = useCallback(() => handleRemove(itemId), [handleRemove, itemId]);
    const getOnChange = useGetOnChange(handleChange, customSkillItem);

    const onTitleChange = getOnChange("title");
    const onCompanyChange = getOnChange("company");
    const onYearChange = getOnChange("year");
    const onAdditionalInfoChange = getOnChange("additionalInfo");
    const handleYearBlur = useYearInputValidation(onYearChange);

    return (
        <div>
            <SimpleField fieldName="title" errors={errors} translates={translates} isRequired={true}>
                <TextInput
                    value={customSkillItem.get("title")}
                    onChange={onTitleChange}
                    placeholder={translates.get("titlePlaceholder")}
                />
            </SimpleField>
            <SimpleField fieldName="company" errors={errors} translates={translates} isRequired={false}>
                <TextInput
                    value={customSkillItem.get("company")}
                    onChange={onCompanyChange}
                    placeholder={translates.get("companyPlaceholder")}
                />
            </SimpleField>
            <SimpleField fieldName="year" errors={errors} translates={translates} isRequired={true}>
                <TextInput
                    value={customSkillItem.get("year")}
                    onChange={onYearChange}
                    placeholder={translates.get("yearPlaceholder")}
                    isNumeric={true}
                    signAfterPoint={0}
                    onBlur={handleYearBlur}
                />
            </SimpleField>
            <SimpleField fieldName="additionalInfo" errors={errors} translates={translates} isRequired={false}>
                <TextareaInput
                    value={customSkillItem.get("additionalInfo")}
                    onChange={onAdditionalInfoChange}
                    placeholder={translates.get("additionalInfoPlaceholder")}
                />
            </SimpleField>
            <ControlButtonsContainer addClass="justify-content-between d-flex">
                <RemoveItemBtn onClick={onRemove}>{translates.get("removeItemBtnText")}</RemoveItemBtn>
                {showAddBtn ? (
                    <AddItemBtn onClick={handleAddItem}>{translates.get("addBtnText")}</AddItemBtn>
                ) : null}
            </ControlButtonsContainer>
        </div>
    );
};

CertificateOrLicenseItem.propTypes = {
    customSkillItem: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleAddItem: PropTypes.func.isRequired,
    handleRemove: PropTypes.func.isRequired,
    translates: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    showAddBtn: PropTypes.bool.isRequired,
};

export default CertificateOrLicenseItem;