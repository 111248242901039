import axios from "axios";

const getAxiosClient = () => axios.create({
    headers: {
        "content-type": "application/json",
        "x-csrf-token": window.csrfToken,
        "x-requested-with": "XMLHttpRequest"
    }
});

export default getAxiosClient;