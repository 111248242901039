import { useDispatch } from "react-redux";
import { fieldValueChanged } from "@cv-store/actions";
import { useCallback } from "react";

/**
 * @param {CvField} field
 * @param {Function} evtToValue
 * @returns {function(...[*]=)}
 */
const useFieldChange = (field, evtToValue) => {
    const dispatch = useDispatch();

    const fieldType = field.type;
    return useCallback((evt) => {
        dispatch(fieldValueChanged(fieldType, (value) => evtToValue(evt, value)));
    }, [dispatch, fieldType, evtToValue]);
};

export default useFieldChange;