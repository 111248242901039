import React from "react";
import PropTypes from "prop-types";
import useFieldChange from "@cv/hooks/useFieldChange";
import CvField from "@cv-store/models/CvField";
import SimpleField from "@cv-components/widgets/form/SimpleField";

const BaseSimpleTextField = ({ field, errors, prepareValue, renderComponent }) => {
    const valueChange = useFieldChange(
        field,
        (inputVal, value) => value.set("value", prepareValue(inputVal))
    );

    return (
        <SimpleField translates={field.translates} errors={errors} isRequired={field.isRequired}>
            {renderComponent(
                field.value.get("value"),
                valueChange,
                field.translates.get("placeholder")
            )}
        </SimpleField>
    );
};

BaseSimpleTextField.defaultProps = {
    prepareValue: (val) => val
};

BaseSimpleTextField.propTypes = {
    field: PropTypes.instanceOf(CvField).isRequired,
    errors: PropTypes.object.isRequired,
    prepareValue: PropTypes.func,
    renderComponent: PropTypes.func.isRequired,
};

export default BaseSimpleTextField;