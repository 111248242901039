import React from "react";
import PropTypes from "prop-types";
import CvField from "@cv-store/models/CvField";
import useFieldChange from "@cv/hooks/useFieldChange";
import RadioList from "@cv-components/widgets/form/RadioList";
import DateInput from "@cv-components/widgets/form/DateInput";
import SimpleField from "@cv-components/widgets/form/SimpleField";

const UserInfoBirthday = ({ field, errors }) => {
    const birthdayChange = useFieldChange(field, (newDate, value) => value.set("birthday", newDate));
    const isHiddenChange = useFieldChange(field, (isHidden, value) => value.set("isHidden", +isHidden));

    return (
        <>
            <SimpleField
                translates={field.translates}
                errors={errors}
                fieldName="birthday"
                isRequired={field.isRequired}
            >
                <DateInput
                    placeholderDate={field.translates.get("dateInput-datePlaceholder")}
                    placeholderMonth={field.translates.get("dateInput-monthPlaceholder")}
                    placeholderYear={field.translates.get("dateInput-yearPlaceholder")}
                    name="birthday"
                    value={field.value.get("birthday")}
                    onChange={birthdayChange}
                    addClass="user-info-birthday d-flex"
                />
            </SimpleField>
            <SimpleField translates={field.translates} errors={errors} fieldName="isHidden">
                <RadioList
                    name={`${field.type}-isHidden`}
                    options={field.config.get("isHiddenOptions")}
                    value={field.value.get("isHidden")}
                    onChange={isHiddenChange}
                />
            </SimpleField>
        </>
    );
};

UserInfoBirthday.propTypes = {
    field: PropTypes.instanceOf(CvField).isRequired,
    errors: PropTypes.object.isRequired,
};

export default React.memo(UserInfoBirthday);