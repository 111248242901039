import getAxiosClient from "@cv/utils/getAxiosClient";

const uploadImage = (url, file, sizes = null) => {
    return new Promise((resolve) => {
        let formData = new FormData();
        formData.append("file", file);

        if (sizes) {
            formData.append("x", sizes.x);
            formData.append("y", sizes.y);
            formData.append("x1", sizes.x1);
            formData.append("y1", sizes.y1);
        }

        getAxiosClient()
            .request({
                method: "POST",
                url: url,
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                data: formData
            })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                }
            });
    });
};

export default uploadImage;