import React, { useCallback } from "react";
import PropTypes from "prop-types";
import DropDown from "@cv-components/widgets/form/DropDown";
import {
    LEVEL_PRIMARY,
    LEVEL_SECONDARY,
    LEVEL_VOCATIONAL,
    LEVEL_HIGHER,
    LEVEL_ADDITIONAL
} from "@cv-components/field/UserEducationEducation/UserEducationEducation";
import RemoveItemBtn from "@cv-components/widgets/RemoveItemBtn/RemoveItemBtn";
import SimpleField from "@cv-components/widgets/form/SimpleField";
import ControlButtonsContainer from "@cv-components/widgets/RemoveItemBtn/ControlButtonsContainer";
import Container from "@cv-components/widgets/Container/Container";

const BaseEducationItem = ({ item, renderFields, onChange, onRemove, translates, config, errors }) => {
    const onEducationLevelChange = useCallback(
        (val) => {
            let newItem = item.set("educationLevel", val);
            switch (val) {
            case LEVEL_PRIMARY:
            case LEVEL_SECONDARY:
                newItem = newItem
                    .set("yearFrom", "")
                    .set("yearTo", "")
                    .set("schoolName", "")
                    .set("additionalInformation", "");

                break;
            case LEVEL_VOCATIONAL:
                newItem = newItem
                    .set("yearFrom", "")
                    .set("yearTo", "")
                    .set("schoolName", "")
                    .set("additionalInformation", "")

                    .set("sphere", null)
                    .set("speciality", null)
                    .set("otherSpeciality", "");
                break;
            case LEVEL_HIGHER:
                newItem = newItem
                    .set("university")
                    .set("otherUniversity")
                    .set("acquiredEduction")
                    .set("yearFrom", "")
                    .set("yearTo", "")
                    .set("primarySphere", null)
                    .set("primarySpeciality", null)
                    .set("primaryOtherSpeciality", "")
                    .set("additionalSphere", null)
                    .set("additionalSpeciality", null)
                    .set("additionalOtherSpeciality", "")
                    .set("additionalInformation", "");
                break;
            case LEVEL_ADDITIONAL:
                newItem = newItem
                    .set("trainingCompany", "")
                    .set("subject", "")
                    .set("year", null)
                    .set("periodCount", null)
                    .set("periodUnit", "")
                    .set("additionalInformation", "");
                break;
            default:
                console.error("Unknown education level");
                break;
            }
            return onChange(newItem);
        },
        [onChange, item]
    );

    return (
        <Container>
            <SimpleField fieldName="educationLevel" translates={translates} errors={errors} isRequired={true}>
                <DropDown
                    value={item.get("educationLevel")}
                    options={config.get("educationLevels")}
                    onChange={onEducationLevelChange}
                    placeholder={translates.get("educationLevelPlaceholder")}
                />
            </SimpleField>
            {renderFields()}
            <ControlButtonsContainer>
                <RemoveItemBtn onClick={onRemove}>{translates.get("removeItemBtnText")}</RemoveItemBtn>
            </ControlButtonsContainer>
        </Container>
    );
};

BaseEducationItem.propTypes = {
    item: PropTypes.object.isRequired,
    renderFields: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    translates: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

export default BaseEducationItem;