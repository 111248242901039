import React from "react";
import PropTypes from "prop-types";
import { List } from "immutable";
import CvFormBlock from "@cv-components/block/CvFormBlock";

import "./CvForm.scss";

const CvFormView = ({ handleFormSubmit, handleFormSubmitAndNew, blocks, showCreateAndNew, translates, previewUrl, previewData }) => {
    return (
        <div className="cv-container">
            <form method="POST" className="hidden" id="preview-form" action={previewUrl} target="_blank">
                <input type="text" name="_csrf-frontend" value={window.csrfToken}/>
                <textarea name="data" value={previewData} />
            </form>
            <form onSubmit={handleFormSubmit}>
                <div className="control-buttons-container">
                    <button className="btn-cv-top" type="submit" form="preview-form">
                        {translates.get("preview")}
                        <img src="/resource/img/icons/editUserInfoIcon.png" alt="" className="cv-preview-icon cv-top-btn-icon"/>
                    </button>
                    <button className="btn-cv-top">
                        {translates.get("save")}
                        <img src="/resource/img/icons/editUserInfoIcon.png" alt="" className="cv-save-icon cv-top-btn-icon"/>
                    </button>
                </div>
                <div class="clear"></div>
                <div className="panel-group">{blocks.map((block, index) => {
                    return (
                        <CvFormBlock
                            key={block.name}
                            number={index+1}
                            block={block}
                        />
                    );
                })}</div>
                <div className="bottom-cv-btns">
                    <button className="btn btn-info btn-cv">{translates.get("submit")}</button>
                    {showCreateAndNew ? (<button className="btn btn-outline-info btn-cv" onClick={handleFormSubmitAndNew}>{translates.get("submitAndNew")}</button>) : null}
                </div>
            </form>
        </div>
    );
};

CvFormView.propTypes = {
    handleFormSubmit: PropTypes.func.isRequired,
    handleFormSubmitAndNew: PropTypes.func.isRequired,
    showCreateAndNew: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    blocks: PropTypes.instanceOf(List).isRequired,
    previewUrl: PropTypes.string.isRequired,
    previewData: PropTypes.string.isRequired,
    translates: PropTypes.object.isRequired
};

export default CvFormView;