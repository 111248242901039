import React from "react";
import { useDispatch } from "react-redux";
import { fieldValueChanged } from "@cv-store/actions";
import { Map } from "immutable";
import Container from "@cv-components/widgets/Container/Container";
import FormDelimiter from "@cv-components/widgets/FormDelimiter/FormDelimiter";
import SimpleField from "@cv-components/widgets/form/SimpleField";
import DropDown from "@cv-components/widgets/form/DropDown";
import useFieldChange from "@cv/hooks/useFieldChange";
import useMultiItems from "@cv/hooks/useMultiItems";
import AddItemBtn from "@cv-components/widgets/RemoveItemBtn/AddItemBtn";
import ControlButtonsContainer from "@cv-components/widgets/RemoveItemBtn/ControlButtonsContainer";

import CustomSkill from "@cv-components/field/SkillsOtherSkills/CustomSkill";

const defaultItem = {
    skillName: null,
    skillEvaluation: null,
};

const SkillsOtherSkills = ({ field, errors }) => {
    const dispatch = useDispatch();
    const onSkillChange = (skill, newVal) => dispatch(fieldValueChanged(field.type, (value) => value.set(skill, newVal)));

    const translates = field.translates;
    const value = field.value;
    const config = field.config;

    const evaluationOptions = config.get("evaluationOptions");
    const { addItem, removeItem, changeItem } = useMultiItems(undefined, defaultItem);

    const handleAddItem = useFieldChange(field, addItem);
    const handleRemoveItem = useFieldChange(field, removeItem);
    const handleItemChange = useFieldChange(field, changeItem);

    return (
        <Container>
            <FormDelimiter>{translates.get("title")}</FormDelimiter>
            {config.get("staticSkills").map((skill) => {
                return (
                    <SimpleField key={skill} fieldName={skill} errors={errors} translates={translates}>
                        <DropDown
                            value={value.get(skill)}
                            options={evaluationOptions}
                            onChange={(newVal) => onSkillChange(skill, newVal)}
                            placeholder={translates.get(`${skill}Placeholder`)}
                        />
                    </SimpleField>
                );
            })}
            <div>
                {value.get("items").map((item, index) => {
                    return (
                        <CustomSkill
                            key={item.get("id")}
                            customSkillItem={item}
                            handleRemove={handleRemoveItem}
                            handleAddItem={handleAddItem}
                            handleChange={handleItemChange}

                            showAddBtn={value.get("items").size === index+1}

                            translates={translates}
                            config={config}
                            errors={errors.getIn(["items", index.toString()]) || Map({})}
                        />
                    );
                })}
            </div>
            {value.get("items").size ? null
                : (
                    <ControlButtonsContainer>
                        <AddItemBtn onClick={handleAddItem}>{translates.get("addBtnText")}</AddItemBtn>
                    </ControlButtonsContainer>
                )
            }
        </Container>
    );
};

export default SkillsOtherSkills;