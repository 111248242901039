import React from "react";
import PropTypes from "prop-types";

const SelectButton = ({ className, onClick, onClear, value, prompt, isDisabled, isClearable }) => {
    return (
        <button
            className={className}
            onClick={onClick}
            disabled={isDisabled}
            type="button"
        >
            <span className="value-container">{value ? value : prompt}</span>
            {isClearable && value ? (<a className={"dalderGlyph-cross clear-value-btn"} onClick={onClear}/>) : null }
        </button>
    );
};

SelectButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    value: PropTypes.string,
    prompt: PropTypes.string,
    isDisabled: PropTypes.bool,
    isClearable: PropTypes.bool,
};

export default SelectButton;