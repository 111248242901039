import React from "react";
import PropTypes from "prop-types";
import ValidationError from "@cv-components/widgets/ValidationError/ValidationError";
import { List } from "immutable";

const FormGroup = ({ children, error, addClass }) => {
    return (
        <div className={`input-container-wrapper ${addClass ? addClass : ""}`}>
            {children}
            <ValidationError error={error}/>
        </div>
    );
};

FormGroup.propTypes = {
    error: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(List)]),
    children: PropTypes.any,
    addClass: PropTypes.string,
};

export default React.memo(FormGroup);
