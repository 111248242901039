import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "@cv-store/reducers";

export default function initializeStore(initialState) {
    if (process.env.NODE_ENV === "production") {
        return createStore(
            rootReducer,
            initialState,
            applyMiddleware(thunk)
        );
    } else {
        const { composeWithDevTools } = require("redux-devtools-extension/developmentOnly");
        return createStore(
            rootReducer,
            initialState,
            composeWithDevTools(applyMiddleware(thunk))
        );
    }
}