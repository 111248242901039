import React, { useCallback } from "react";
import PropTypes from "prop-types";
import TextInput from "@cv-components/widgets/form/TextInput";
import YearsPeriodBlock from "@cv-components/field/UserEducationEducation/YearsPeriodBlock";
import TextareaInput from "@cv-components/widgets/form/TextareaInput";
import SimpleField from "@cv-components/widgets/form/SimpleField";

const SchoolEducationItem = ({ item, getOnChange, translates, errors }) => {
    const onSchoolNameChange = useCallback(getOnChange("schoolName"), [getOnChange]);
    const onAdditionalInfoChange = useCallback(getOnChange("additionalInformation"), [getOnChange]);

    return (
        <>
            <SimpleField fieldName="schoolName" translates={translates} errors={errors} isRequired={true}>
                <TextInput
                    value={item.get("schoolName")}
                    onChange={onSchoolNameChange}
                    placeholder={translates.get("schoolNamePlaceholder")}
                />
            </SimpleField>
            <YearsPeriodBlock
                item={item}
                errors={errors}
                translates={translates}
                getOnChange={getOnChange}
            />
            <SimpleField fieldName="additionalInformation" translates={translates} errors={errors}>
                <TextareaInput
                    value={item.get("additionalInformation")}
                    onChange={onAdditionalInfoChange}
                    placeholder={translates.get("additionalInformationPlaceholder")}
                />
            </SimpleField>
        </>
    );
};

SchoolEducationItem.propTypes = {
    item: PropTypes.object.isRequired,
    getOnChange: PropTypes.func.isRequired,
    translates: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

export default SchoolEducationItem;