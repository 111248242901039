import { useCallback } from "react";

const modifierToNumber = (v) => v ? +v : v;

const modifierSrcVal = (v) => v;

const useGetOnChange = (handleChange, item) => {
    return useCallback(
        (fieldName, modifier = modifierSrcVal) => (val) => handleChange(item.set(fieldName, modifier(val))),
        [handleChange, item]
    );
};

export { useGetOnChange as default, modifierSrcVal, modifierToNumber };