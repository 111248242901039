import React from "react";
import PropTypes  from "prop-types";

const AddItemBtn = ({ children, onClick, disabled }) => {
    return (
        <button onClick={onClick} type="button" disabled={disabled} className="action-item-btn add-item-btn">
            <img src="/resource/img/icons/plus.svg" alt="add button" className="action-item-icon add-item-icon"/>
            {children}
        </button>
    );
};

AddItemBtn.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.any,
    disabled: PropTypes.bool
};

export default AddItemBtn;