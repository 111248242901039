import React from "react";
import PropTypes from "prop-types";
import FieldLayout from "@cv-components/widgets/FieldLayout/FieldLayout";
import FormGroup from "@cv-components/widgets/form/FormGroup";

const _SimpleField = ({ fieldName, children, translates, errors, isRequired }) => {
    return (
        <FieldLayout
            label={translates.get(fieldName ? `${fieldName}Label` : "label")}
            helpText={translates.get(fieldName ? `${fieldName}HelpText` : "helpText")}
            isRequired={isRequired}
            hasError={!!errors.get(fieldName ? fieldName : "value")}
        >
            <FormGroup error={errors.get(fieldName ? fieldName : "value")}>
                {children}
            </FormGroup>
        </FieldLayout>
    );
};

_SimpleField.defaultProps = {
    isRequired: false
};

const SimpleField = React.memo(_SimpleField);

SimpleField.propTypes = {
    fieldName: PropTypes.string,
    children: PropTypes.any,
    translates: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    isRequired: PropTypes.bool
};

export default SimpleField;