import uid from "uid";
import { Map } from "immutable";
import { useCallback } from "react";

const useMultiItems = (canAddItem = () => true, defaultItem = {}) => {
    const addItem = (newData, value) => {
        if (!canAddItem(value.get("items"))) {
            return;
        }

        const data = {
            id: uid(),
            ...defaultItem
        };

        return value.set("items", value.get("items").push(Map(data)));
    };

    const removeItem = useCallback((id, value) => {
        const newItemsList = value.get("items").filter((item) => item.get("id") !== id);

        return value.set("items", newItemsList);
    }, []);

    const changeItem = useCallback((newData, value) => {
        let itemIndex = value.get("items").findIndex((item) => item.get("id") === newData.get("id"));

        return value.setIn(["items", itemIndex], newData);
    }, []);

    return {
        addItem, removeItem, changeItem
    };
};

export default useMultiItems;