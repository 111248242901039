import React from "react";
import "./container.scss";
import PropTypes from "prop-types";

const Container = ({ children, addClass }) => {
    return (
        <div className={`field-logical-container ${addClass ? addClass : ""}`}>
            {children}
        </div>
    );
};

Container.propTypes = {
    addClass: PropTypes.string,
};

export default Container;