import React from "react";
import PropTypes from "prop-types";
import FieldLayout from "@cv-components/widgets/FieldLayout/FieldLayout";
import CvField from "@cv-store/models/CvField";
import useFieldChange from "@cv/hooks/useFieldChange";
import FormGroup from "@cv-components/widgets/form/FormGroup";
import DropDown from "@cv-components/widgets/form/DropDown";
import _ from "lodash";
import "@cv-components/field/UserInfoChildren/UserInfoChildren.scss";

const Ages = ({ childrenCount, onChange, value }) => {
    return (
        <>
            {_.range(0, childrenCount).map((i) => {
                return (
                    <DropDown
                        key={i}
                        value={value.get(i) || 1}
                        options={_.range(1, 18).map((age) => ({ label: age, value: age }))}
                        onChange={(val) => {
                            let newVal = value.setSize(childrenCount);
                            newVal = newVal.set(i, val ? +val : "");
                            onChange(newVal);
                        }}
                    />
                );
            })}
        </>
    );
};

const UserInfoChildren = ({ field, errors }) => {
    const childrenCountChange = useFieldChange(
        field,
        (inputVal, value) => {
            const newChildrenCount = +inputVal;
            let newChildrenAges = value.get("childrenAges");
            while (newChildrenAges.size < newChildrenCount) {
                newChildrenAges = newChildrenAges.push(1);
            }

            return value
                .set("childrenCount", newChildrenCount)
                .set("childrenAges", newChildrenAges);
        }
    );
    const agesChange = useFieldChange(field, (ages, value) => value.set("childrenAges", ages));

    return (
        <FieldLayout
            label={field.translates.get("label")}
            helpText={field.translates.get("helpText")}
            isRequired={field.isRequired}
            hasError={!!errors.size}
        >
            <div className="user-info-children">
                <FormGroup error={errors.get("childrenCount")} addClass="select-children">
                    <DropDown
                        value={field.value.get("childrenCount")}
                        options={_.range(0, field.config.get("maxChildrenCount")+1).map((count) => {
                            return {
                                label: count,
                                value: count
                            };
                        })}
                        onChange={childrenCountChange}
                    />
                </FormGroup>
                <FormGroup error={errors.get("childrenAges")} addClass="select-children-age">
                    <Ages
                        childrenCount={+field.value.get("childrenCount")}
                        value={field.value.get("childrenAges")}
                        onChange={agesChange}
                    />
                </FormGroup>
            </div>
        </FieldLayout>
    );
};

UserInfoChildren.propTypes = {
    field: PropTypes.instanceOf(CvField).isRequired,
    errors: PropTypes.object.isRequired,
};

export default React.memo(UserInfoChildren);