import React from "react";
import FormDelimiter from "@cv-components/widgets/FormDelimiter/FormDelimiter";
import Container from "@cv-components/widgets/Container/Container";
import SimpleField from "@cv-components/widgets/form/SimpleField";
import useFieldChange from "@cv/hooks/useFieldChange";
import TextareaInput from "@cv-components/widgets/form/TextareaInput";

const AdditionalInformationPersonalQualities = ({ field, errors }) => {
    const onPersonalQualitiesChange = useFieldChange(field, (inputVal, value) => value.set("personalQualities", inputVal));
    const onStrengthsChange = useFieldChange(field, (inputVal, value) => value.set("strengths", inputVal));
    const onFlawsChange = useFieldChange(field, (inputVal, value) => value.set("flaws", inputVal));
    const onInterestsChange = useFieldChange(field, (inputVal, value) => value.set("interests", inputVal));
    const translates = field.translates;
    const value = field.value;

    return (
        <Container>
            <FormDelimiter>{translates.get("title")}</FormDelimiter>
            <SimpleField fieldName="personalQualities" translates={translates} errors={errors}>
                <TextareaInput
                    onChange={onPersonalQualitiesChange}
                    value={value.get("personalQualities")}
                    placeholder={translates.get("personalQualitiesPlaceholder")}
                />
            </SimpleField>
            <SimpleField fieldName="strengths" translates={translates} errors={errors}>
                <TextareaInput
                    onChange={onStrengthsChange}
                    value={value.get("strengths")}
                    placeholder={translates.get("strengthsPlaceholder")}
                />
            </SimpleField>
            <SimpleField fieldName="flaws" translates={translates} errors={errors}>
                <TextareaInput
                    onChange={onFlawsChange}
                    value={value.get("flaws")}
                    placeholder={translates.get("flawsPlaceholder")}
                />
            </SimpleField>
            <SimpleField fieldName="interests" translates={translates} errors={errors}>
                <TextareaInput
                    onChange={onInterestsChange}
                    value={value.get("interests")}
                    placeholder={translates.get("interestsPlaceholder")}
                />
            </SimpleField>
        </Container>
    );
};

export default AdditionalInformationPersonalQualities;