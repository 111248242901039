import React from "react";
import PropTypes from "prop-types";
import CvBlock from "@cv-store/models/CvBlock";
import CvFormField from "@cv-components/field/CvFormField";

import "@cv-components/block/CvFormBlock.scss";

/**
 * @param {Number} number
 * @param {CvBlock} block
 * @returns {*}
 * @constructor
 */
const CvFormBlock = ({ number, block }) => {
    return (
        <div className="panel form-section">
            <div className="panel-heading">
                <h4 className="panel-title">
                    <a className="title" data-toggle="collapse" href={`#${block.name}`} aria-expanded="true">
                        {number}. {block.title}
                    </a>
                </h4>
            </div>
            <div id={block.name} className="panel-collapse collapse in" aria-expanded="true">
                <div className="panel-body content table-container ">
                    {block.fields.map((fieldType) => {
                        return (<CvFormField key={fieldType} fieldType={fieldType}/>);
                    })}
                </div>
            </div>
        </div>
    );
};

CvFormBlock.propTypes = {
    number: PropTypes.number.isRequired,
    block: PropTypes.instanceOf(CvBlock).isRequired
};

export default React.memo(CvFormBlock);