import React from "react";
import { useDispatch } from "react-redux";
import { fieldValueChanged } from "@cv-store/actions";
import useFieldChange from "@cv/hooks/useFieldChange";
import useMultiItems from "@cv/hooks/useMultiItems";
import Container from "@cv-components/widgets/Container/Container";
import FormDelimiter from "@cv-components/widgets/FormDelimiter/FormDelimiter";
import ControlButtonsContainer from "@cv-components/widgets/RemoveItemBtn/ControlButtonsContainer";
import FieldLayout from "@cv-components/widgets/FieldLayout/FieldLayout";
import LocationWidget from "@cv-components/widgets/form/LocationWidget/LocationWidget";
import LocationItem from "@cv-components/field/FutureWorkRequirementsWorkLocationAndSchedule/LocationItem";
import CheckboxInput from "@cv-components/widgets/form/CheckboxInput";
import SimpleField from "@cv-components/widgets/form/SimpleField";
import DropDown from "@cv-components/widgets/form/DropDown";
import "@cv-components/field/FutureWorkRequirementsWorkLocationAndSchedule/FutureWorkRequirementsWorkLocationAndSchedule.scss";

const FutureWorkRequirementsWorkLocationAndSchedule = ({ field, errors }) => {
    const translates = field.translates;
    const value = field.value;
    const config = field.config;

    const dispatch = useDispatch();
    const fieldType = field.type;

    const workFromHomeChange = useFieldChange(field, (inputVal, value) => value.set("workFromHome", +inputVal.checked));
    const workScheduleChange = useFieldChange(field, (inputVal, value) => value.set("workSchedule", inputVal));
    const canStartChange = useFieldChange(field, (inputVal, value) => value.set("canStart", inputVal));

    const getOnChange = (evtToValue) => {
        return (newValue) => {
            dispatch(fieldValueChanged(fieldType, (value) => evtToValue(newValue, value)));
        };
    };

    const clearLocationSelect = getOnChange((evt, value) => {
        return value
            .set("countryId", null)
            .set("adminUnitId", null)
            .set("adminUnitName", null)
            .set("parentAdminUnitId", null)
            .set("parentAdminUnitName", null);

    });
    const canAddItem = () => {
        return !!value.get("parentAdminUnitId") || (+value.get("countryId") && +value.get("countryId") !== config.get("countryHasAdminUnits"));
    };

    const { addItem, removeItem } = useMultiItems(canAddItem, {
        countryId: value.get("countryId"),
        countryName: config.get("countries").find((country) => country.value === +value.get("countryId"))?.label,
        parentAdminUnitId: value.get("parentAdminUnitId"),
        parentAdminUnitName: value.get("parentAdminUnitName"),
        adminUnitId: value.get("adminUnitId"),
        adminUnitName: value.get("adminUnitName"),
    });

    const handleAddItem = useFieldChange(field, addItem);
    const handleRemove = useFieldChange(field, removeItem);

    const handleSelectLocation = () => {
        handleAddItem();
        setTimeout(() => clearLocationSelect(), 0);
    };

    return (
        <Container>
            <FormDelimiter>{translates.get("title")}</FormDelimiter>
            <FieldLayout className="location-info-message">
                {translates.get("locationInfoMessage")}
            </FieldLayout>
            <LocationWidget
                getOnChange={getOnChange}
                isRequired={false}
                translates={translates}
                errors={errors}
                additionalErrors={errors.get("items")?.toJS()}
                item={field.value}
                config={config}
            />
            <ControlButtonsContainer>
                <button type="button" className="btn btn-info float-right" onClick={handleSelectLocation} disabled={!canAddItem()}>
                    {translates.get("selectLocationBtnText")}
                </button>
            </ControlButtonsContainer>
            {field.value.get("items").size ? (
                <FieldLayout label={translates.get("selectedLocationsLabel")} inputContainerClassName="d-flex flex-wrap location-item-container">
                    {value.get("items").map((item) => (
                        <LocationItem
                            key={item.get("id")}
                            item={item}
                            handleRemove={handleRemove}
                        />
                    ))}
                </FieldLayout>
            ) : null}
            <FieldLayout hasError={errors.get("workFromHome")} helpText={translates.get("workFromHomeHelpText")}>
                <CheckboxInput
                    label={translates.get("workFromHomeLabel")}
                    value={1}
                    name="workFromHome"
                    checked={Boolean(+field.value.get("workFromHome"))}
                    onChange={workFromHomeChange}
                />
            </FieldLayout>
            <SimpleField fieldName="workSchedule" translates={translates} errors={errors} isRequired={false}>
                <DropDown
                    value={field.value.get("workSchedule")}
                    placeholder={translates.get("workSchedulePlaceholder")}
                    onChange={workScheduleChange}
                    options={config.get("scheduleOptions")}
                />
            </SimpleField>
            <SimpleField fieldName="canStart" translates={translates} errors={errors} isRequired={false}>
                <DropDown
                    value={field.value.get("canStart")}
                    placeholder={translates.get("canStartPlaceholder")}
                    onChange={canStartChange}
                    options={config.get("canStartOptions")}
                />
            </SimpleField>
        </Container>
    );
};

export default FutureWorkRequirementsWorkLocationAndSchedule;
