import React from "react";
import PropTypes from "prop-types";
import { List } from "immutable";

import "@cv-components/widgets/ValidationError/ValidationError.scss";

const ValidationError = ({ error }) => {
    if (!error) {
        return null;
    }

    return (
        <span className="help-block">
            {error}
        </span>
    );
};

ValidationError.propTypes = {
    error: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(List)])
};

export default ValidationError;