import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "@cv-components/widgets/FieldLayout/FieldLayout.scss";
import HelpButton from "@cv-components/widgets/HelpButton/HelpButton";

const FieldLayout = ({ renderLabel, label, children, hasError, isRequired, helpText, className, inputContainerClassName }) => {
    return (
        <div className={classNames("field-block form-group", className, { "has-error": hasError, "required": isRequired })}>
            <div className="control-label">{renderLabel ? renderLabel() : label}</div>
            <div className={classNames("field-container", inputContainerClassName)}>{children}</div>
            {helpText ? <HelpButton text={helpText}/> : null}
        </div>
    );
};

FieldLayout.propTypes = {
    renderLabel: PropTypes.func,
    label: PropTypes.string,
    helpText: PropTypes.string,
    children: PropTypes.any,
    isRequired: PropTypes.bool,
    hasError: PropTypes.bool,
    className: PropTypes.string,
    inputContainerClassName: PropTypes.string,
};

export default FieldLayout;