export const FIELD_TYPE_USER_INFO__USERNAME = "user-info_username";
export const FIELD_TYPE_USER_INFO__PHOTO = "user-info_photo";
export const FIELD_TYPE_USER_INFO__GENDER = "user-info_gender";
export const FIELD_TYPE_USER_INFO__BIRTHDAY = "user-info_birthday";
export const FIELD_TYPE_USER_INFO__CHILDREN = "user-info_children";
export const FIELD_TYPE_USER_INFO__LOCATION = "user-info_location";
export const FIELD_TYPE_USER_INFO__PHONE_NUMBER = "user-info_phone-number";
export const FIELD_TYPE_USER_INFO__EMAIL = "user-info_email";
export const FIELD_TYPE_USER_INFO__EDUCATION_LEVEL = "user-info_education-level";
export const FIELD_TYPE_USER_INFO__STATUS = "user-info_status";
export const FIELD_TYPE_USER_INFO__INTRO = "user-info_intro";

export const FIELD_TYPE_USER_EDUCATION__EDUCATION = "user-education_education";

export const FIELD_TYPE_WORK_EXPERIENCE__EXPERIENCE = "work-experience_experience";

export const FIELD_TYPE_SKILLS__LANGUAGE_SKILLS = "skills_language-skills";
export const FIELD_TYPE_SKILLS__DRIVING_LICENSE = "skills_driving-license";
export const FIELD_TYPE_SKILLS__OTHER_SKILLS = "skills_other-skills";
export const FIELD_TYPE_SKILLS__LICENSES_AND_CERTIFICATES = "skills_licenses-and-certificates";

export const FIELD_TYPE_FUTURE_REQUIREMENTS__CHOICE = "future-requirements_choice";
export const FIELD_TYPE_FUTURE_REQUIREMENTS__WORK_LOCATION_AND_SCHEDULE = "future-requirements_work-location-and-schedule";
export const FIELD_TYPE_FUTURE_REQUIREMENTS__INFORMATION_ABOUT_SALARY = "future-requirements_information-about-salary";

export const FIELD_TYPE_ADDITIONAL_INFORMATION__PUBLICATIONS = "additional-information_publications";
export const FIELD_TYPE_ADDITIONAL_INFORMATION__ORGANIZATIONS = "additional-information_organizations";
export const FIELD_TYPE_ADDITIONAL_INFORMATION__PERSONAL_QUALITIES = "additional-information_personal-qualities";
export const FIELD_TYPE_ADDITIONAL_INFORMATION__INFO = "additional-information_info";

export const FIELD_TYPE_SETTINGS__TITLE = "settings_title";
export const FIELD_TYPE_SETTINGS__DESIGN = "settings_design";

