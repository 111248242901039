import { Record } from "immutable";

let defaultProps = {
    name: null,
    title: null,
    fields: null
};

export default class CvBlock extends Record(defaultProps) {
    static fromObject(data) {
        let orderedFields = data.fields.slice();
        orderedFields.sort((a, b) => {
            if (a.order < b.order) {
                return -1;
            } else if (a.order > b.order) {
                return 1;
            } else {
                return 0;
            }
        });

        data.fields = orderedFields.map((field) => field.type);

        return new CvBlock(data);
    }
}
