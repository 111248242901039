import React from "react";
import PropTypes from "prop-types";
import { Map } from "immutable";
import useMultiItems from "@cv/hooks/useMultiItems";
import RecommendationItem from "@cv-components/field/WorkExperienceExperience/RecommendationItem";
import ControlButtonsContainer from "@cv-components/widgets/RemoveItemBtn/ControlButtonsContainer";
import AddItemBtn from "@cv-components/widgets/RemoveItemBtn/AddItemBtn";
import "@cv-components/field/WorkExperienceExperience/RecommendationItemsList.scss";

const defaultItem = {
    recName: "",
    recPosition: "",
    recPhone: "",
    recEmail: "",
};

const RecommendationItemsList = ({ workExperienceItem, handleChange, translates, errors }) => {
    const { addItem, removeItem, changeItem } = useMultiItems(undefined, defaultItem);

    const handleAddItem = () => handleChange(addItem(defaultItem, workExperienceItem));
    const handleRemoveItem = (id) => handleChange(removeItem(id, workExperienceItem));
    const handleChangeRecommendedItem = (newRecommendedItem) => handleChange(changeItem(newRecommendedItem, workExperienceItem));

    const items = workExperienceItem.get("items");

    return (
        <div className="recommendation-items-list" >
            <div>
                {items.map((recommendation, index) => (
                    <RecommendationItem
                        key={recommendation.get("id")}
                        item={recommendation}
                        handleRemove={handleRemoveItem}
                        handleAddItem={handleAddItem}
                        handleChange={handleChangeRecommendedItem}
                        translates={translates}
                        showAddBtn={index+1===items.size}
                        errors={errors.get(index) || Map({})}
                    />
                ))}
            </div>
            {!items.size ? (
                <ControlButtonsContainer>
                    <AddItemBtn onClick={handleAddItem}>{translates.get("addRecommendationBtnText")}</AddItemBtn>
                </ControlButtonsContainer>
            ) : null}
        </div>
    );
};

RecommendationItemsList.propTypes = {
    workExperienceItem: PropTypes.object.isRequired,
    translates: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired
};

export default React.memo(RecommendationItemsList);