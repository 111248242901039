import React from "react";
import PropTypes from "prop-types";
import CvField from "@cv-store/models/CvField";
import { useDispatch } from "react-redux";
import { fieldValueChanged } from "@cv-store/actions";
import LocationWidget from "@cv-components/widgets/form/LocationWidget/LocationWidget";

const UserInfoLocation = ({ field, errors }) => {
    const dispatch = useDispatch();
    const fieldType = field.type;

    const getOnChange = (evtToValue) => {
        return (newValue) => {
            dispatch(fieldValueChanged(fieldType, (value) => evtToValue(newValue, value)));
        };
    };

    return (
        <LocationWidget
            item={field.value}
            getOnChange={getOnChange}
            config={field.config}
            translates={field.translates}
            errors={errors}
            isRequired={field.isRequired}
        />
    );
};

UserInfoLocation.propTypes = {
    field: PropTypes.instanceOf(CvField).isRequired,
    errors: PropTypes.object.isRequired,
};

export default React.memo(UserInfoLocation);