import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import FormGroup from "@cv-components/widgets/form/FormGroup";
import DropDown from "@cv-components/widgets/form/DropDown";
import FieldLayout from "@cv-components/widgets/FieldLayout/FieldLayout";
import TextInput from "@cv-components/widgets/form/TextInput";
import getAxiosClient from "@cv/utils/getAxiosClient";
import useGetOnChange from "@cv/hooks/useGetOnChange";
import usePrevious from "../../../../hooks/usePrevious";

const WorkPositionWidget = ({ item, translates, errors, config, handleChange }) => {
    const getOnChange = useGetOnChange(handleChange, item);

    const sphere = item.get("sphere");
    const [positionOptions, setPositionOptions] = useState([]);

    const url = config.get("getWorkPositionsUrl");
    const otherWorkPositionOption = config.get("otherWorkPositionOption");

    const prevItem = usePrevious(item);

    useEffect(() => {
        setPositionOptions([]);
        if (sphere) {
            getAxiosClient()
                .get(url+sphere)
                .then((response) => {
                    let options = response.data;
                    options.push(otherWorkPositionOption);
                    setPositionOptions(options);

                    if (prevItem && prevItem.get("sphere") !== sphere) {
                        onPositionChange(options.length ? options[0].value : null);
                    }
                });
        }
    }, [sphere, url, otherWorkPositionOption]);// eslint-disable-line

    const onPositionChange = useCallback((val) => {
        let newItem = item.set("position", val);
        if (val !== -1) {
            newItem = newItem.set("otherPosition", null);
        }
        handleChange(newItem);
    }, [handleChange, item]);

    const onOtherWorkPosition = getOnChange("otherPosition");

    const position = item.get("position");

    return (
        <FieldLayout
            label={translates.get("positionLabel")}
            helpText={translates.get("positionHelpText")}
            hasError={!!errors.get("position")}
        >
            <FormGroup error={errors.get("position")}>
                <DropDown
                    onChange={onPositionChange}
                    disabled={!sphere}
                    options={positionOptions}
                    value={position}
                    placeholder={translates.get("positionPlaceholder")}
                />
                {position === -1 ? (
                    <TextInput
                        onChange={onOtherWorkPosition}
                        value={item.get("otherPosition")}
                        placeholder={translates.get("otherPositionPlaceholder")}
                    />
                ) : null}
            </FormGroup>
        </FieldLayout>
    );
};

WorkPositionWidget.propTypes = {
    item: PropTypes.object.isRequired,
    translates: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
};

export default React.memo(WorkPositionWidget);