import React from "react";
import PropTypes from "prop-types";
import FormGroup from "@cv-components/widgets/form/FormGroup";
import FieldLayout from "@cv-components/widgets/FieldLayout/FieldLayout";
import useGetOnChange from "@cv/hooks/useGetOnChange";
import DateInput from "@cv-components/widgets/form/DateInput";

const MonthYearPeriodWidget = ({ item, translates, errors, config, handleChange }) => {
    const getOnChange = useGetOnChange(handleChange, item);

    const onDateFromChange = getOnChange("dateFrom");
    const onDateToChange = getOnChange("dateTo");

    let allErrors = [];
    if (errors.get("dateFrom")) {
        allErrors = allErrors.concat(errors.get("dateFrom"));
    }

    if (errors.get("dateTo")) {
        allErrors = allErrors.concat(errors.get("dateTo"));
    }

    return (
        <FieldLayout
            label={translates.get("periodLabel")}
            helpText={translates.get("periodHelpText")}
            isRequired={true}
            hasError={!!allErrors.length}
        >
            <FormGroup addClass="d-flex month-year-period-wrapper" error={allErrors}>
                <DateInput
                    placeholderMonth={translates.get("dateInput-monthPlaceholder")}
                    placeholderYear={translates.get("dateInput-yearPlaceholder")}
                    value={item.get("dateFrom")}
                    onChange={onDateFromChange}
                    showDate={false}
                    addClass="d-flex month-year-period"
                />
                <span className="liner-month-year-period">-</span>
                <DateInput
                    placeholderMonth={translates.get("dateInput-monthPlaceholder")}
                    placeholderYear={translates.get("dateInput-yearPlaceholder")}
                    value={item.get("dateTo")}
                    onChange={onDateToChange}
                    showDate={false}
                    addClass="d-flex month-year-period end"
                />
            </FormGroup>
        </FieldLayout>
    );
};

MonthYearPeriodWidget.propTypes = {
    item: PropTypes.object.isRequired,
    translates: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
};

export default React.memo(MonthYearPeriodWidget);