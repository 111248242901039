import React from "react";
import PropTypes from "prop-types";

import "@cv-components/widgets/HelpButton/HelpButton.scss";

const HelpButton = ({ text }) => {
    return (
        <div className="help-button">
            <span className="help-icon">
                ?
                <div className="text-block">
                    {text}
                </div>
            </span>
        </div>
    );
};

HelpButton.propTypes = {
    text: PropTypes.string.isRequired
};

export default HelpButton;