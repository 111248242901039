import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { List } from "immutable";
import useGetOnChange from "@cv/hooks/useGetOnChange";
import RemoveItemBtn from "@cv-components/widgets/RemoveItemBtn/RemoveItemBtn";
import DropDown from "@cv-components/widgets/form/DropDown";
import TextInput from "@cv-components/widgets/form/TextInput";
import SimpleField from "@cv-components/widgets/form/SimpleField";
import TextareaInput from "@cv-components/widgets/form/TextareaInput";
import WorkPositionWidget from "@cv-components/widgets/form/WorkPositionWidget";
import MonthYearPeriodWidget from "@cv-components/field/WorkExperienceExperience/MonthYearPeriodWidget";
import RecommendationItemsList from "@cv-components/field/WorkExperienceExperience/RecommendationItemsList";
import FormDelimiter from "@cv-components/widgets/FormDelimiter/FormDelimiter";
import ControlButtonsContainer from "@cv-components/widgets/RemoveItemBtn/ControlButtonsContainer";
import Container from "@cv-components/widgets/Container/Container";

const WorkExperienceItem = ({ item, handleRemove, handleChange, translates, config, errors }) => {
    const itemId = item.get("id");
    const onRemove = useCallback(() => handleRemove(itemId), [handleRemove, itemId]);
    const getOnChange = useGetOnChange(handleChange, item);

    const onCompanyNameChange = getOnChange("companyName");

    const onSphereChange = useCallback((val) => {
        handleChange(item.set("sphere", val).set("position", null));
    }, [handleChange, item]);

    const onPositionDescriptionChange = getOnChange("positionDescription");

    const onIndustryChange = getOnChange("industry");
    const onCompanyTypeChange = getOnChange("companyType");
    const onCompanySizeChange = getOnChange("companySize");

    const onReasonForLivingChange = getOnChange("reasonForLiving");
    const onAdditionalInfoChange = getOnChange("additionalInformation");

    return (
        <Container>
            <SimpleField fieldName="companyName" translates={translates} errors={errors} isRequired={true}>
                <TextInput
                    value={item.get("companyName")}
                    onChange={onCompanyNameChange}
                    placeholder={translates.get("companyNamePlaceholder")}
                />
            </SimpleField>
            <MonthYearPeriodWidget
                handleChange={handleChange}
                item={item}
                translates={translates}
                errors={errors}
                config={config}
            />
            <SimpleField fieldName="sphere" translates={translates} errors={errors}>
                <DropDown
                    options={config.get("workSphereOptions")}
                    onChange={onSphereChange}
                    value={item.get("sphere")}
                    placeholder={translates.get("spherePlaceholder")}
                />
            </SimpleField>
            <WorkPositionWidget
                handleChange={handleChange}
                item={item}
                translates={translates}
                errors={errors}
                config={config}
            />
            <SimpleField fieldName="positionDescription" translates={translates} errors={errors} isRequired={true}>
                <TextareaInput
                    value={item.get("positionDescription")}
                    onChange={onPositionDescriptionChange}
                    placeholder={translates.get("positionDescriptionPlaceholder")}
                />
            </SimpleField>
            <SimpleField fieldName="industry" translates={translates} errors={errors}>
                <DropDown
                    value={item.get("industry")}
                    onChange={onIndustryChange}
                    options={config.get("industryOptions")}
                    placeholder={translates.get("industryPlaceholder")}
                />
            </SimpleField>
            <SimpleField fieldName="companyType" translates={translates} errors={errors}>
                <DropDown
                    value={item.get("companyType")}
                    onChange={onCompanyTypeChange}
                    options={config.get("companyTypeOptions")}
                    placeholder={translates.get("companyTypePlaceholder")}
                />
            </SimpleField>
            <SimpleField fieldName="companySize" translates={translates} errors={errors}>
                <DropDown
                    value={item.get("companySize")}
                    onChange={onCompanySizeChange}
                    options={config.get("companySizeOptions")}
                    placeholder={translates.get("companySizePlaceholder")}
                />
            </SimpleField>
            <SimpleField fieldName="reasonForLiving" translates={translates} errors={errors}>
                <TextareaInput
                    value={item.get("reasonForLiving")}
                    onChange={onReasonForLivingChange}
                    placeholder={translates.get("reasonForLivingPlaceholder")}
                />
            </SimpleField>
            <SimpleField fieldName="additionalInformation" translates={translates} errors={errors}>
                <TextareaInput
                    value={item.get("additionalInformation")}
                    onChange={onAdditionalInfoChange}
                    placeholder={translates.get("additionalInformationPlaceholder")}
                />
            </SimpleField>
            <FormDelimiter>{translates.get("recommendationsText")}</FormDelimiter>
            <RecommendationItemsList
                workExperienceItem={item}
                handleChange={handleChange}
                translates={translates}
                errors={errors.get("items") || List([])}
            />
            <ControlButtonsContainer>
                <RemoveItemBtn onClick={onRemove}>{translates.get("removeWorkExperienceItemBtnText")}</RemoveItemBtn>
            </ControlButtonsContainer>
        </Container>
    );
};

WorkExperienceItem.propTypes = {
    item: PropTypes.object.isRequired,
    handleRemove: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    translates: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

export default React.memo(WorkExperienceItem);