import React from "react";
import { Map } from "immutable";
import useFieldChange from "@cv/hooks/useFieldChange";
import useMultiItems from "@cv/hooks/useMultiItems";

import FutureWorkRequirementsItem from "@cv-components/field/FutureWorkRequirementsChoice/FutureWorkRequirementsItem";

const defaultItem = {
    label: "",
    company: "",
    year: "",
    additionalInformation: "",
};

const FutureWorkRequirementsChoice = ({ field, errors }) => {
    const translates = field.translates;
    const value = field.value;

    const { changeItem } = useMultiItems(undefined, defaultItem);

    const handleItemChange = useFieldChange(field, changeItem);

    return (
        <div>
            {value.get("items").map((item, index) => {
                return (
                    <FutureWorkRequirementsItem
                        key={item.get("id")}
                        item={item}
                        handleChange={handleItemChange}
                        config={field.config}
                        translates={translates}
                        errors={errors.getIn(["items", index.toString()]) || Map({})}
                    />
                );
            })}
        </div>
    );
};

export default FutureWorkRequirementsChoice;