import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DropDown from "@cv-components/widgets/form/DropDown";
import _ from "lodash";
import * as dayjs from "dayjs";

const twoSignsNumber = (month) => {
    return month.toString().padStart(2, "0");
};

const numberToSelect2Format = (date) => {
    return {
        label: twoSignsNumber(date),
        value: date
    };
};

const DateInput = ({ onChange, value, placeholderDate, placeholderMonth, placeholderYear, showDate, addClass }) => {
    const momentDate = value ? dayjs(value, "YYYY-MM-DD") : dayjs();

    const [date, setDate] = useState(value ? momentDate.date() : null);
    const [month, setMonth] = useState(value ? momentDate.month() + 1 : null);
    const [year, setYear] = useState(value ? momentDate.year() : null);

    useEffect(() => {
        setDate(value ? momentDate.date() : null);
        setMonth(value ? momentDate.month() + 1 : null);
        setYear(value ? momentDate.year() : null);
    }, [value]);//eslint-disable-line

    const currentYear = dayjs().year();

    const days = _.range(1, momentDate.daysInMonth()+1).map((day) => {
        return numberToSelect2Format(day);
    });

    days.unshift({
        label: " ",
        value: ""
    });

    /**
     * @type {{label: string, value: T}[]}
     */
    const months = _.range(1, 13).map((month) => {
        // const label = dayjs(month, "M").format("MMM");
        const label = dayjs(`${dayjs().year()}-${month}-01`).format("MMM");
        return {
            label: label,
            value: month
        };
    });

    months.unshift({
        label: " ",
        value: ""
    });

    const years = _.range(1900, currentYear+1).reverse().map((year) => {
        return numberToSelect2Format(year);
    });

    years.unshift({
        label: " ",
        value: ""
    });

    useEffect(() => {
        if (
            date !== momentDate.date()
            || month !== (momentDate.month()+1)
            || year !== momentDate.year()
        ) {
            if (year && month && (date || !showDate)) {
                const normalized = dayjs(`${year}-${twoSignsNumber(month)}-${showDate ? date : "01"}`).format("YYYY-MM-DD");
                onChange(normalized);
            } else {
                onChange(null);
            }
        }
    }, [date, month, year]);//eslint-disable-line

    return (
        <div className={addClass} >
            {showDate ? (
                <DropDown
                    onChange={setDate}
                    options={days}
                    value={date}
                    placeholder={placeholderDate}
                />
            ) : null}
            <DropDown
                onChange={setMonth}
                options={months}
                value={month}
                placeholder={placeholderMonth}
            />
            <DropDown
                onChange={setYear}
                options={years}
                value={year}
                placeholder={placeholderYear}
            />
        </div>
    );
};

DateInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    placeholderDate: PropTypes.string,
    placeholderMonth: PropTypes.string,
    placeholderYear: PropTypes.string,
    showDate: PropTypes.bool,
    addClass: PropTypes.string,
};

DateInput.defaultProps = {
    showDate: true
};

export default DateInput;