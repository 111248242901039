import React, { useState } from "react";
import Form from "@cv/CvForm";
import getAxiosClient from "@cv/utils/getAxiosClient";

const LicenseAgreement = ({ loadaction, translates, copy, form, ...formProps }) => {
    const [translatesParsed] = useState(JSON.parse(translates) || {});
    const [formData, setFormData] = useState(null);
    const isCopy = Number.parseInt(copy);

    const loadAndShowForm = async () => {
        if (isCopy) {
            setFormData(JSON.parse(form));
        } else {
            const response = await getAxiosClient().get(loadaction);
            if (response.status === 200) {
                setFormData(response.data);
            }
        }

    };

    if (formData) {
        return (
            <div>
                <Form
                    {...formProps}
                    translates={translates}
                    form={JSON.stringify(formData)}
                    showdraftconfirm={(+!isCopy).toString()}
                />
            </div>
        );
    }

    return (
        <div className="tab-pane fade in active">
            <div className="license-agreement cv-info">

                <div className="text-center">
                    <button
                        type="button" onClick={(e) => {
                            if (!window.showLoginModalIfUnauthorized(e)) {
                                const element = document.getElementById('cv-agreement');
                                element.classList.add('hidden');
                                loadAndShowForm();
                            }
                        }}
                        className="btn btn-info license-btn"
                    >{translatesParsed.licenseBtnText}</button>
                </div>
            </div>
        </div>
    );
};

LicenseAgreement.defaultProps = {
    copy: "0"
};

export default LicenseAgreement;