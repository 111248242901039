import React, { useCallback } from "react";
import PropTypes from "prop-types";
import SpecialityWidget from "@cv-components/field/UserEducationEducation/SpecialityWidget";
import FieldLayout from "@cv-components/widgets/FieldLayout/FieldLayout";
import FormGroup from "@cv-components/widgets/form/FormGroup";
import TextInput from "@cv-components/widgets/form/TextInput";
import YearsPeriodBlock from "@cv-components/field/UserEducationEducation/YearsPeriodBlock";
import TextareaInput from "@cv-components/widgets/form/TextareaInput";
import SimpleField from "@cv-components/widgets/form/SimpleField";

const VocationalEducationItem = ({ item, getOnChange, translates, errors, config }) => {
    const onSchoolNameChange = useCallback(getOnChange("schoolName"), [getOnChange]);
    const onAdditionalInfoChange = useCallback(getOnChange("additionalInformation"), [getOnChange]);

    return (
        <div>
            <FieldLayout
                label={translates.get("schoolNameLabel")}
                helpText={translates.get("schoolNameHelpText")}
                isRequired={true}
                hasError={!!errors.get("schoolName")}
            >
                <FormGroup error={errors.get("schoolName")}>
                    <TextInput
                        value={item.get("schoolName")}
                        onChange={onSchoolNameChange}
                        placeholder={translates.get("schoolNamePlaceholder")}
                    />
                </FormGroup>
            </FieldLayout>
            <SpecialityWidget
                getOnChange={getOnChange}
                item={item}
                translates={translates}
                errors={errors}
                config={config}
            />
            <YearsPeriodBlock
                item={item}
                errors={errors}
                translates={translates}
                getOnChange={getOnChange}
            />
            <SimpleField fieldName="additionalInformation" translates={translates} errors={errors}>
                <TextareaInput
                    value={item.get("additionalInformation")}
                    onChange={onAdditionalInfoChange}
                    placeholder={translates.get("additionalInformationPlaceholder")}
                />
            </SimpleField>
        </div>
    );
};

VocationalEducationItem.propTypes = {
    item: PropTypes.object.isRequired,
    getOnChange: PropTypes.func.isRequired,
    translates: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

export default VocationalEducationItem;