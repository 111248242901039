import React from "react";
import PropTypes from "prop-types";
import CvField from "@cv-store/models/CvField";
import BaseSimpleTextField from "@cv-components/widgets/BaseSimpleTextField";
import DropDown from "@cv-components/widgets/form/DropDown";

const SimpleDropdownField = ({ field, errors }) => {
    return (
        <BaseSimpleTextField
            field={field}
            errors={errors}
            renderComponent={(value, onChange, placeholder) => (
                <DropDown
                    value={value}
                    options={field.config.get("options")}
                    onChange={onChange}
                    placeholder={placeholder}
                />
            )}
        />
    );
};

SimpleDropdownField.propTypes = {
    field: PropTypes.instanceOf(CvField).isRequired,
    errors: PropTypes.object.isRequired,
};

export default React.memo(SimpleDropdownField);