import React, { useCallback } from "react";
import PropTypes from "prop-types";
import "./LocationItem.scss";

const LocationItem = ({ item, handleRemove }) => {
    const itemId = item.get("id");
    const onRemove = useCallback(() => handleRemove(itemId), [handleRemove, itemId]);

    let units = [];
    if (item.get("countryName")) {
        units.push(item.get("countryName"));
    }

    if (item.get("parentAdminUnitName")) {
        units.push(item.get("parentAdminUnitName"));
    }

    if (item.get("adminUnitName")) {
        units.push(item.get("adminUnitName"));
    }

    return (
        <div className="location-item">
            {units.join("/")}
            <button className="remove-location-btn" type="button" onClick={onRemove}>
                <img src="/resource/img/icons/plus.svg" alt="remove button" className="remove-location-icon"/>

            </button>
        </div>
    );
};

LocationItem.propTypes = {
    item: PropTypes.object.isRequired,
    handleRemove: PropTypes.func.isRequired,
};

export default LocationItem;