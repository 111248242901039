import React from "react";
import CvForm from "./cv/CvForm";
import ReactLoader from "react-loader";
import * as dayjs from "dayjs";
import "dayjs/locale/ru";
import "dayjs/locale/lv";
import LicenseAgreement from "@cv/LicenseAgreement/LicenseAgreement";

dayjs.locale(window.config.language);

const react = new ReactLoader("#app");

react.component("cv-update", (props) =>  <CvForm {...props}/>);
react.component("cv-create", (props) =>  <LicenseAgreement {...props}/>);
react.render();
