import { useCallback } from "react";
import * as dayjs from "dayjs";

const useYearInputValidation = (onYearChange) => {
    return useCallback((e) => {
        const year = e.target.value;
        const currentYear = dayjs().year();
        if (year < 1900) {
            onYearChange(1900);
        }

        if (year > currentYear) {
            onYearChange(currentYear);
        }
    }, [onYearChange]);
};

export default useYearInputValidation;