import { handleActions } from "redux-actions";
import { fromJS, List, Map } from "immutable";
import {
    FIELD_VALUE_CHANGED,
    SET_FORM_DATA,
    SET_FIELDS_ERRORS,
    SET_DRAFT_VALUES,
    SET_TRANSLATES
} from "@cv-store/action-types";
import CvField from "@cv-store/models/CvField";
import CvBlock from "@cv-store/models/CvBlock";

const defaultState = Map({
    blocks: null,
    fields: null,
    translates: null,
    errors: Map({}),
    changesCounter: 1
});

function getBlockFieldsMap(blocks, fields) {
    let blockFields = {};
    for (let blockName of Object.keys(blocks)) {
        blockFields[blockName] = [];
    }

    for (let key in fields) {
        if (Object.hasOwnProperty.call(fields, key)) {
            const field = fields[key];
            blockFields[field.block].push({
                type: field.type,
                order: +field.order,
            });
        }
    }

    return blockFields;
}

function getBlocksList(blockData, fieldsData) {
    const blockFields = getBlockFieldsMap(blockData, fieldsData);

    let blocks = [];
    for (let blockName in blockData) {
        if (Object.hasOwnProperty.call(blockData, blockName)) {
            blocks.push(CvBlock.fromObject({
                name: blockName,
                title: blockData[blockName],
                fields: blockFields[blockName]
            }));
        }
    }

    return blocks;
}

export default handleActions({
    [SET_FORM_DATA]: (state, { payload }) => {
        const blocks = getBlocksList(payload.blocks, payload.fields);

        let fields = {};
        for (let fieldType in payload.fields) {
            if (Object.hasOwnProperty.call(payload.fields, fieldType)) {
                fields[fieldType] = CvField.fromObject(payload.fields[fieldType]);
            }
        }

        return state
            .set("blocks", List(blocks))
            .set("fields", Map(fields));
    },
    [SET_DRAFT_VALUES]: (state, { payload }) => {
        let fields = state.get("fields");
        for (let fieldType in payload) {
            if (Object.hasOwnProperty.call(payload, fieldType)) {
                fields = fields.setIn([fieldType, "value"], fromJS(payload[fieldType]));
            }
        }

        return state.set("fields", fields);
    },
    [FIELD_VALUE_CHANGED]: (state, { payload }) => {
        return state
            .setIn(["fields", payload.type, "value"], payload.value)
            .set("changesCounter", state.get("changesCounter") + 1);
    },
    [SET_TRANSLATES]: (state, { payload }) => {
        return state.set("translates", Map(payload));
    },
    [SET_FIELDS_ERRORS]: (state, { payload }) => {
        return state.set("errors", fromJS(payload));
    }
}, defaultState);