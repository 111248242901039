import React from "react";
import { isImmutable } from "immutable";
import Container from "@cv-components/widgets/Container/Container";
import FormDelimiter from "@cv-components/widgets/FormDelimiter/FormDelimiter";
import SimpleField from "@cv-components/widgets/form/SimpleField";
import useFieldChange from "@cv/hooks/useFieldChange";
import CheckboxList from "@cv-components/widgets/form/CheckboxList";
import FormGroup from "@cv-components/widgets/form/FormGroup";
import FieldLayout from "@cv-components/widgets/FieldLayout/FieldLayout";
import TextInput from "@cv-components/widgets/form/TextInput";
import RadioList from "@cv-components/widgets/form/RadioList";
import useYearInputValidation from "@cv/hooks/useYearInputValidation";
import "@cv-components/field/SkillsDrivingLicense/SkillsDrivingLicense.scss";

const SkillsDrivingLicense = ({ field, errors }) => {
    const translates = field.translates;
    const value = field.value;

    const onCategoryChange = useFieldChange(field, (inputVal, value) => value.set("categories", inputVal));
    const onLicenseDateChange = useFieldChange(field, (inputVal, value) => value.set("licenseDate", inputVal));
    const onUseOwnCarChange = useFieldChange(field, (inputVal, value) => value.set("useOwnCar", +inputVal));

    const onLicenseDateBlur = useYearInputValidation(onLicenseDateChange);

    let categories = value.get("categories");
    if (categories) {
        categories = isImmutable(categories) ? categories.toJS() : categories;
    } else {
        categories = [];
    }

    let allErrors = [];

    if (errors.get("licenseDate")) {
        allErrors = allErrors.concat(errors.get("licenseDate"));
    }

    if (errors.get("useOwnCar")) {
        allErrors = allErrors.concat(errors.get("useOwnCar"));
    }

    return (
        <Container>
            <FormDelimiter>{translates.get("title")}</FormDelimiter>
            <SimpleField fieldName="categories" errors={errors} translates={translates} isRequired={false}>
                <CheckboxList
                    name="categories"
                    value={categories}
                    options={field.config.get("categoryOptions")}
                    onChange={onCategoryChange}
                />
            </SimpleField>
            <FieldLayout
                label={translates.get("licenseDateLabel")}
                helpText={translates.get("licenseDateHelpText")}
                isRequired={false}
                hasError={!!allErrors.length}
            >
                <FormGroup error={allErrors}>
                    <TextInput
                        onChange={onLicenseDateChange}
                        onBlur={onLicenseDateBlur}
                        value={value.get("licenseDate")}
                        placeholder={translates.get("licenseDatePlaceholder")}
                        isNumeric={true}
                        signAfterPoint={0}
                    />
                    <p className="own-car-question">{translates.get("canUseOwnCarQuestion")}</p>
                    <RadioList
                        name="useOwnCar"
                        options={field.config.get("useOwnCarOptions")}
                        value={value.get("useOwnCar")}
                        onChange={onUseOwnCarChange}
                    />
                </FormGroup>
            </FieldLayout>
        </Container>
    );
};

export default SkillsDrivingLicense;