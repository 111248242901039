import React from "react";
import PropTypes from "prop-types";
import CvField from "@cv-store/models/CvField";
import useMultiItems from "@cv/hooks/useMultiItems";
import useFieldChange from "@cv/hooks/useFieldChange";
import { List, Map } from "immutable";
import WorkExperienceItem from "@cv-components/field/WorkExperienceExperience/WorkExperienceItem";
import ControlButtonsContainer from "@cv-components/widgets/RemoveItemBtn/ControlButtonsContainer";
import AddItemBtn from "@cv-components/widgets/RemoveItemBtn/AddItemBtn";

const defaultItem = {
    company: "",
    sphere: null,
    position: null,
    otherPosition: "",
    positionDescription: "",
    dateFrom: null,
    dateTo: null,
    industry: null,
    companyType: null,
    companySize: null,
    reasonForLiving: "",
    additionalInformation: "",
    items: List([])
};

const WorkExperienceExperience = ({ field, errors }) => {
    const { addItem, removeItem, changeItem } = useMultiItems(undefined, defaultItem);

    const handleAddItem = useFieldChange(field, addItem);
    const handleRemoveItem = useFieldChange(field, removeItem);
    const handleItemChange = useFieldChange(field, changeItem);

    return (
        <>
            <div>
                {field.value.get("items").map((item, index) => (
                    <WorkExperienceItem
                        key={item.get("id")}
                        item={item}
                        handleRemove={handleRemoveItem}
                        handleChange={handleItemChange}
                        translates={field.translates}
                        config={field.config}
                        errors={errors.getIn(["items", index.toString()]) || Map({})}
                    />
                ))}
            </div>
            <ControlButtonsContainer>
                <AddItemBtn type="button" onClick={handleAddItem}>
                    {field.translates.get("addWorkExperienceBtnText")}
                </AddItemBtn>
            </ControlButtonsContainer>
        </>
    );
};

WorkExperienceExperience.propTypes = {
    field: PropTypes.instanceOf(CvField).isRequired,
    errors: PropTypes.object.isRequired,
};

export default React.memo(WorkExperienceExperience);