import React, { useCallback } from "react";
import PropTypes from "prop-types";
import {
    LEVEL_PRIMARY,
    LEVEL_SECONDARY,
    LEVEL_VOCATIONAL,
    LEVEL_HIGHER,
    LEVEL_ADDITIONAL
} from "@cv-components/field/UserEducationEducation/UserEducationEducation";
import BaseEducationItem from "@cv-components/field/UserEducationEducation/items/BaseEducationItem";
import SchoolEducationItem from "@cv-components/field/UserEducationEducation/items/SchoolEducationItem";
import VocationalEducationItem from "@cv-components/field/UserEducationEducation/items/VocationalEducationItem";
import HigherEducationItem from "@cv-components/field/UserEducationEducation/items/HigherEducationItem";
import AdditionalEducationItem from "@cv-components/field/UserEducationEducation/items/AdditionalEducationItem";
import useGetOnChange from "@cv/hooks/useGetOnChange";

const EducationItem = ({ item, handleRemove, handleChange, translates, config, errors }) => {
    const itemId = item.get("id");
    const getOnChange = useGetOnChange(handleChange, item);

    const onRemove = useCallback(() => handleRemove(itemId), [handleRemove, itemId]);

    let render;

    switch (item.get("educationLevel")) {
    case LEVEL_PRIMARY:
    case LEVEL_SECONDARY:
        render = () => {
            return (
                <SchoolEducationItem
                    item={item}
                    handleChange={handleChange}
                    translates={translates}
                    config={config}
                    errors={errors}
                    getOnChange={getOnChange}
                />
            );
        };
        break;
    case LEVEL_VOCATIONAL:
        render = () => {
            return (
                <VocationalEducationItem
                    item={item}
                    handleChange={handleChange}
                    translates={translates}
                    config={config}
                    errors={errors}
                    getOnChange={getOnChange}
                />
            );
        };
        break;
    case LEVEL_HIGHER:
        render = () => {
            return (
                <HigherEducationItem
                    item={item}
                    handleChange={handleChange}
                    translates={translates}
                    config={config}
                    errors={errors}
                    getOnChange={getOnChange}
                />
            );
        };
        break;

    case LEVEL_ADDITIONAL:
        render = () => {
            return (
                <AdditionalEducationItem
                    item={item}
                    handleChange={handleChange}
                    translates={translates}
                    config={config}
                    errors={errors}
                    getOnChange={getOnChange}
                />
            );
        };
        break;
    default:
        console.error("Unknown education level");
        render = () => null;
        break;
    }

    return (
        <BaseEducationItem
            item={item}
            renderFields={render}
            onRemove={onRemove}
            onChange={handleChange}
            translates={translates}
            config={config}
            errors={errors}
        />
    );
};

EducationItem.propTypes = {
    item: PropTypes.object.isRequired,
    handleRemove: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    translates: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

export default React.memo(EducationItem);