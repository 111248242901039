import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import SelectButton from "./SelectButton";
import SelectContainer from "./SelectContainer";
import SelectOptionsList from "./SelectOptionsList";
import "./Select.scss";

const Select = ({ value, onChange, options, isDisabled, isSearchable, placeholder, debug, name }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [positionClass, setPositionClass] = useState("bottom");
    const [optionsToDisplay, setOptionsToDisplay] = useState(options);
    const [search, setSearch] = useState(null);

    const widgetRef = useRef();

    const promptIndex = options.findIndex((option) => {
        return option.value === "" || option.value === null;
    });

    const isClearable = promptIndex !== -1;

    const filterAndSetAvailableOptions = useCallback((inputValue) => {
        if (inputValue) {
            let newOptions = options.filter((option) => {
                return option.label.toLowerCase().indexOf(inputValue) !== -1;
            });

            setOptionsToDisplay(newOptions);
            setSearch(inputValue);
        } else {
            setOptionsToDisplay(options);
            setSearch(inputValue);
        }
    }, [options, setSearch]);

    const handleSearch = useCallback((e) => {
        const inputValue = e.target.value?.toLowerCase();

        filterAndSetAvailableOptions(inputValue);
    }, [filterAndSetAvailableOptions]);

    const handleOpen = useCallback(() => {
        if (isDisabled) {
            return;
        }
        setIsOpen(true);
    }, [isDisabled, setIsOpen]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
        handleSearch({ target: { value: "" } });
        setPositionClass("bottom");
    }, [setIsOpen, handleSearch]);

    const toggleOpen = useCallback(() => {
        isOpen ? handleClose() : handleOpen();
    }, [isOpen, handleClose, handleOpen]);

    const handleClear = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onChange(options[promptIndex]);
        setIsOpen(false);
    };

    const handleSelect = useCallback((option) => {
        onChange(option);
        setIsOpen(false);
    }, [onChange, setIsOpen]);

    const clickOutside = useCallback((e) => {
        if (!isOpen) {
            return;
        }

        if (!widgetRef.current.contains(e.target)) {
            handleClose();
        }
    }, [widgetRef, isOpen, handleClose]);

    useEffect(() => {
        document.addEventListener("click", clickOutside);

        return () => document.removeEventListener("click", clickOutside);
    }, [clickOutside]);

    useEffect(() => {
        filterAndSetAvailableOptions(search);
    }, [filterAndSetAvailableOptions, search]);

    if (debug) {
        console.log(name, value, options);//eslint-disable-line
    }

    return (
        <div
            ref={widgetRef}
            className={classNames("react-dalder-select", { "open": isOpen })}
        >
            <SelectButton
                onClick={toggleOpen}
                onClear={handleClear}
                isClearable={isClearable}
                value={value?.label?.toString()}
                prompt={placeholder}
                isDisabled={isDisabled}
                className="select-button"
            />
            {isOpen ? (
                <SelectContainer
                    maxHeight={150}
                    getParentRect={() => widgetRef.current.getBoundingClientRect()}
                    positionClass={positionClass}
                    setPositionClass={setPositionClass}
                    renderBody={(maxHeight) => (
                        <SelectOptionsList
                            withSearch={isSearchable}
                            search={search}
                            handleSearch={handleSearch}
                            maxHeight={maxHeight}
                            options={optionsToDisplay}
                            value={value}
                            handleSelect={handleSelect}
                        />
                    )}
                />
            ) : null}
        </div>
    );
};

export default Select;