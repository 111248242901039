import { useCallback, useEffect } from "react";
import LS from "local-storage";
import getAxiosClient from "@cv/utils/getAxiosClient";
import uid from "uid";

const useDraft = (creation, draftAction, deleteDraftAction, draft, key = "cv-draft") => {
    const _getStorageDrafts = useCallback(() => LS.get(key) || [], [key]);

    const _setStorageGrafts = useCallback((data) => LS.set(key, data), [key]);

    const _getStorageDraftIndex = (allDrafts) => {
        return allDrafts.findIndex((draft) => {
            return draft.id === window.cvDraftId;
        });
    };

    useEffect(() => {
        if (creation) {
            const draft = _getStorageDrafts().pop();
            window.cvDraftId = draft ? draft.id : uid();
        }
    }, [creation, _getStorageDrafts]);

    const getStorageDraft = useCallback(() => {
        const allDrafts = _getStorageDrafts();
        const draftIndex = _getStorageDraftIndex(allDrafts);

        if (draftIndex !== -1) {
            return allDrafts[draftIndex];
        }

        return null;
    }, [_getStorageDrafts]);

    const saveStorageDraft = useCallback((newData) => {
        const allDrafts = _getStorageDrafts();
        const draftIndex = _getStorageDraftIndex(allDrafts);

        if (draftIndex !== -1) {
            allDrafts[draftIndex].data = newData;
        } else {
            allDrafts.push({
                id: window.cvDraftId,
                data: newData
            });
        }

        _setStorageGrafts(allDrafts);
    }, [_getStorageDrafts, _setStorageGrafts]);

    const deleteStorageDraft = useCallback(() => {
        let allDrafts = _getStorageDrafts();
        const draftIndex = _getStorageDraftIndex(allDrafts);
        if (draftIndex !== -1) {
            allDrafts.splice(draftIndex, 1);
            _setStorageGrafts(allDrafts);
        }
    }, [_getStorageDrafts, _setStorageGrafts]);

    const getDbDraft = useCallback(() => {
        if (draft) {
            let parsedDraft = JSON.parse(draft);

            return parsedDraft ? { data: parsedDraft } : null;
        }

        return null;
    }, [draft]);

    const saveDbDraft = useCallback((newData) => {
        return getAxiosClient().post(draftAction, newData);
    }, [draftAction]);

    const deleteDbDraft = useCallback(() => getAxiosClient().post(deleteDraftAction), [deleteDraftAction]);

    if (creation) {
        return [getStorageDraft, saveStorageDraft, deleteStorageDraft];
    } else {
        return [getDbDraft, saveDbDraft, deleteDbDraft];
    }
};

export default useDraft;