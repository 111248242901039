import React from "react";
import PropTypes from "prop-types";
import Container from "@cv-components/widgets/Container/Container";
import FormDelimiter from "@cv-components/widgets/FormDelimiter/FormDelimiter";
import useFieldChange from "@cv/hooks/useFieldChange";
import useMultiItems from "@cv/hooks/useMultiItems";
import AddItemBtn from "@cv-components/widgets/RemoveItemBtn/AddItemBtn";
import ControlButtonsContainer from "@cv-components/widgets/RemoveItemBtn/ControlButtonsContainer";

const SimpleMultiItemsField = ({ field, defaultItem, renderItems, addClass }) => {
    const translates = field.translates;
    const value = field.value;

    const { addItem, removeItem, changeItem } = useMultiItems(undefined, defaultItem);

    const handleAddItem = useFieldChange(field, addItem);
    const handleRemoveItem = useFieldChange(field, removeItem);
    const handleItemChange = useFieldChange(field, changeItem);

    return (
        <Container addClass={addClass}>
            <FormDelimiter>{translates.get("title")}</FormDelimiter>
            <div>
                {renderItems(value.get("items"), handleRemoveItem, handleItemChange, handleAddItem)}
            </div>
            {value.get("items").size ? null
                : (
                    <ControlButtonsContainer>
                        <AddItemBtn onClick={handleAddItem}>{translates.get("addBtnText")}</AddItemBtn>
                    </ControlButtonsContainer>
                )
            }
        </Container>
    );
};

SimpleMultiItemsField.propTypes = {
    field: PropTypes.shape({
        value: PropTypes.object,
        config: PropTypes.object,
        translates: PropTypes.object
    }).isRequired,
    defaultItem: PropTypes.object.isRequired,
    renderItems: PropTypes.func.isRequired,
    addClass: PropTypes.string,
};

export default SimpleMultiItemsField;