import React, { useCallback } from "react";
import PropTypes from "prop-types";

const TextareaInput = ({ value, onChange, placeholder = null }) => {
    const handleChange = useCallback((e) => {
        onChange(e.target.value);
    }, [onChange]);

    return (
        <textarea
            className="form-control textarea-input"
            value={value || ""}
            onChange={handleChange}
            placeholder={placeholder}
            rows="5"
        />
    );
};

TextareaInput.propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

export default TextareaInput;
