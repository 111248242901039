import React from "react";
import PropTypes from "prop-types";

const Spinner = () => {
    return (
        <div className="lds-roller-wrapper lds-roller-wrapper-image-preview">
            <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

const ImgPreview = ({ loading, imagePreview, onDeleteClick }) => {
    return (
        <div className="image-block">
            { loading ? <Spinner/> : null}
            {imagePreview ? (<img src={imagePreview} alt="preview" className="cv-photo" />) : null}
            <button type="button" onClick={onDeleteClick} className="image-delete">
                <img src="/resource/img/delite.svg" alt="close"  className="image-delete-icon"/>
            </button>
        </div>
    );
};

ImgPreview.propTypes = {
    loading: PropTypes.bool,
    imagePreview: PropTypes.string,
    onDeleteClick: PropTypes.func
};

export default ImgPreview;