import React from "react";
import PropTypes from "prop-types";
import CheckboxInput from "@cv-components/widgets/form/CheckboxInput";

const CheckboxList = ({ name, options, value, onChange }) => {

    const handleChange  = (data) => {
        let newValue;
        if (data.checked) {
            newValue = value.slice();
            newValue.push(data.value);
        } else {
            newValue = value.slice();
            newValue.splice(value.indexOf(data.value), 1);
        }
        onChange(newValue);
    };

    return (
        <div className="d-flex flex-wrap">
            {options.map((option) => (
                <CheckboxInput
                    key={`name-${option.value}`}
                    label={option.label}
                    name={name}
                    value={option.value}
                    checked={value.includes(option.value)}
                    onChange={handleChange}
                />
            ))}
        </div>
    );
};

CheckboxList.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.array
};

export default CheckboxList;