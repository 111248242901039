import React from "react";
import PropTypes from "prop-types";
import FieldLayout from "@cv-components/widgets/FieldLayout/FieldLayout";
import CvField from "@cv-store/models/CvField";
import useFieldChange from "@cv/hooks/useFieldChange";
import FormGroup from "@cv-components/widgets/form/FormGroup";
import TextInput from "@cv-components/widgets/form/TextInput";

const UserInfoNameSurname = ({ field, errors }) => {
    const firstNameChange = useFieldChange(field, (inputVal, value) => value.set("firstName", inputVal));
    const lastNameChange = useFieldChange(field, (inputVal, value) => value.set("lastName", inputVal));

    return (
        <FieldLayout
            label={field.translates.get("label")}
            helpText={field.translates.get("helpText")}
            isRequired={field.isRequired}
            hasError={!!errors.size}
        >
            <div className="d-flex">
                <FormGroup error={errors.get("firstName")}>
                    <TextInput
                        value={field.value.get("firstName")}
                        onChange={firstNameChange}
                        placeholder={field.translates.get("placeholder_firstName")}
                    />
                </FormGroup>
                <FormGroup error={errors.get("lastName")}>
                    <TextInput
                        value={field.value.get("lastName")}
                        onChange={lastNameChange}
                        placeholder={field.translates.get("placeholder_lastName")}
                    />
                </FormGroup>
            </div>
        </FieldLayout>
    );
};

UserInfoNameSurname.propTypes = {
    field: PropTypes.instanceOf(CvField).isRequired,
    errors: PropTypes.object.isRequired,
};

export default React.memo(UserInfoNameSurname);