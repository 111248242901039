import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./ChangePhotoDialogModal.scss";

export const CloseButton = ({ onClick, children, className }) => {
    return (
        <button type="button" data-dismiss="modal" className={className} onClick={() => {
            setTimeout(onClick, 0);
        }}>
            {children}
        </button>
    );
};

const Modal = ({ id, children, className, onClose }) => {
    const modalRef = useRef();

    useEffect(() => {
        const element = modalRef.current; // eslint-disable-line

        window.$(element).modal("show");
        window.$(element).on("hidden.bs.modal", onClose);

        return () => {
            window.$(element).off("hidden.bs.modal", onClose);
        };
    }, [modalRef, onClose]);

    return (
        <div id={id} ref={modalRef} className={classNames("modal fade", className)} role="dialog">
            <div className="modal-dialog change-photo-dialog">
                <div className="modal-content">
                    <div className="modal-body clearfix">
                        <CloseButton onClick={onClose} className="close-button pull-right">
                            <img src="/resource/img/icons/close-select-icon.svg" alt="close"/>
                        </CloseButton>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

Modal.propTypes = {
    id: PropTypes.any,
    children: PropTypes.any,
    className: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};

export default Modal;
