import React, { useCallback } from "react";
import PropTypes from "prop-types";
import SpecialityWidget from "@cv-components/field/UserEducationEducation/SpecialityWidget";
import FieldLayout from "@cv-components/widgets/FieldLayout/FieldLayout";
import YearsPeriodBlock from "@cv-components/field/UserEducationEducation/YearsPeriodBlock";
import DropDown, { AsyncDropDown } from "@cv-components/widgets/form/DropDown";
import FormGroup from "@cv-components/widgets/form/FormGroup";
import getAxiosClient from "@cv/utils/getAxiosClient";
import { isImmutable } from "immutable";
import TextInput from "@cv-components/widgets/form/TextInput";
import TextareaInput from "@cv-components/widgets/form/TextareaInput";
import SimpleField from "@cv-components/widgets/form/SimpleField";
import FormDelimiter from "@cv-components/widgets/FormDelimiter/FormDelimiter";

const _UniversityField = ({ item, getOnChange, config, errors, translates }) => {
    const onOtherUniversityChange = useCallback(getOnChange("otherUniversity"), [getOnChange]);
    const onUniversityChange = useCallback(getOnChange("university"), [getOnChange]);

    const promiseOptions = async (search) => {
        const res = await getAxiosClient()
            .get(config.get("getUniversitiesUrl"), {
                params: {
                    q: search
                }
            });

        const options = res.data;
        options.push(config.get("otherUniversityOption"));

        return options;
    };

    const university = item.get("university");
    const universityValue = isImmutable(university) ? university.toJS() : university;

    let allErrors = [];
    if (errors.get("university")) {
        allErrors = allErrors.concat(errors.get("university"));
    }

    if (errors.get("otherUniversity")) {
        allErrors = allErrors.concat(errors.get("otherUniversity"));
    }

    return (
        <FieldLayout
            label={translates.get("universityLabel")}
            helpText={translates.get("universityHelpText")}
            isRequired={true}
            hasError={!!allErrors.length}
        >
            <FormGroup error={allErrors}>
                <AsyncDropDown
                    onChange={onUniversityChange}
                    value={universityValue === -1 ? null : universityValue}
                    placeholder={translates.get("universityPlaceholder")}
                    load={promiseOptions}
                />
                {universityValue && (universityValue.value === -1) ? (
                    <TextInput
                        onChange={onOtherUniversityChange}
                        value={item.get("otherUniversity")}
                        placeholder={translates.get("otherUniversityPlaceholder")}
                    />
                ) : null}
            </FormGroup>
        </FieldLayout>
    );
};

const UniversityField = React.memo(_UniversityField);

const HigherEducationItem = ({ item, config, translates, errors, getOnChange }) => {
    const onAdditionalInfoChange = useCallback(getOnChange("additionalInformation"), [getOnChange]);
    const onAcquiredEductionChange = useCallback(getOnChange("acquiredEduction"), [getOnChange]);

    return (
        <div>
            <UniversityField
                item={item}
                getOnChange={getOnChange}
                errors={errors}
                translates={translates}
                config={config}
            />
            <FieldLayout
                label={translates.get("acquiredEductionLabel")}
                helpText={translates.get("acquiredEductionHelpText")}
                isRequired={false}
                hasError={!!errors.get("acquiredEduction")}
            >
                <FormGroup error={errors.get("acquiredEduction")}>
                    <DropDown
                        onChange={onAcquiredEductionChange}
                        value={item.get("acquiredEduction")}
                        options={config.get("acquiredEductionOptions")}
                        placeholder={translates.get("acquiredEductionPlaceholder")}
                    />
                </FormGroup>
            </FieldLayout>
            <YearsPeriodBlock
                item={item}
                errors={errors}
                translates={translates}
                getOnChange={getOnChange}
            />
            <FormDelimiter>{translates.get("primaryDelimiterText")}</FormDelimiter>
            <SpecialityWidget
                item={item}
                getOnChange={getOnChange}
                errors={errors}
                translates={translates}
                config={config}
                prefix="primary"
            />
            <FormDelimiter>{translates.get("additionalDelimiterText")}</FormDelimiter>
            <SpecialityWidget
                item={item}
                getOnChange={getOnChange}
                errors={errors}
                translates={translates}
                config={config}
                prefix="additional"
            />
            <FormDelimiter>{translates.get("additionalAboutHigherEducationDelimiter")}</FormDelimiter>
            <SimpleField fieldName="additionalInformation" translates={translates} errors={errors}>
                <TextareaInput
                    value={item.get("additionalInformation")}
                    onChange={onAdditionalInfoChange}
                    placeholder={translates.get("additionalInformationPlaceholder")}
                />
            </SimpleField>
        </div>
    );
};

HigherEducationItem.propTypes = {
    item: PropTypes.object.isRequired,
    getOnChange: PropTypes.func.isRequired,
    config: PropTypes.object.isRequired,
    translates: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

export default React.memo(HigherEducationItem);