import React from "react";
import PropTypes  from "prop-types";

const RemoveItemBtn = ({ children, onClick, addClass }) => {
    return (
        <button onClick={onClick} type="button" className={`action-item-btn remove-item-btn ${addClass ? addClass : ""}`}>
            <img src="/resource/img/icons/plus.svg" alt="remove button" className="action-item-icon remove-item-icon"/>
            {children}
        </button>
    );
};

RemoveItemBtn.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.any,
    addClass: PropTypes.string,
};

export default RemoveItemBtn;