import React, { useCallback } from "react";
import PropTypes from "prop-types";

const RadioList = ({ name, value, label, checked, onChange }) => {
    const id = `${name}-${value}`;

    const handleChange = useCallback((e) => onChange(e.target.value), [onChange]);

    return (
        <div className="input-container radio-container">
            <input
                type="radio"
                id={id}
                name={name}
                value={value}
                checked={checked}
                onChange={handleChange}
            />
            <label
                className="color-inherit"
                htmlFor={id}
            >
                {label}
            </label>
        </div>
    );
};

RadioList.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
};

export default RadioList;