import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import FormGroup from "@cv-components/widgets/form/FormGroup";
import TextInput from "@cv-components/widgets/form/TextInput";
import FieldLayout from "@cv-components/widgets/FieldLayout/FieldLayout";
import DropDown from "@cv-components/widgets/form/DropDown";
import getAxiosClient from "@cv/utils/getAxiosClient";
import { modifierToNumber } from "@cv/hooks/useGetOnChange";

const getFieldName = (name, prefix = null) => {
    if (prefix) {
        return prefix + name.charAt(0).toUpperCase() + name.slice(1);
    }

    return name;
};

const SpecialityWidget = ({ item, getOnChange, errors, translates, config, prefix }) => {
    const sphereFieldName = getFieldName("sphere", prefix);
    const specialityFieldName = getFieldName("speciality", prefix);
    const otherSpecialityFieldName = getFieldName("otherSpeciality", prefix);

    const sphere = item.get(sphereFieldName);
    const speciality = item.get(specialityFieldName);
    const otherSpeciality = item.get(otherSpecialityFieldName);

    const [specialities, setSpecialities] = useState([]);

    const onSphereChange = useCallback(getOnChange(sphereFieldName, modifierToNumber), [getOnChange]);
    const onSpecialityChange = useCallback(getOnChange(specialityFieldName, modifierToNumber), [getOnChange]);
    const onOtherSpecialityChange = useCallback(getOnChange(otherSpecialityFieldName), [getOnChange]);

    const specialitiesUrl = config.get("getSpecialitiesUrl");
    const getSpecialities = useCallback((id) => {
        return getAxiosClient().get(specialitiesUrl+id);
    }, [specialitiesUrl]);

    useEffect(() => {
        if (sphere) {
            getSpecialities(sphere).then((response) => {
                const options = response.data.slice();
                options.push(config.get("otherSpecialityOption"));
                setSpecialities(options);
            });
        } else {
            setSpecialities([]);
        }
    }, [sphere]);//eslint-disable-line

    return (
        <div>
            <FieldLayout
                label={translates.get("sphereLabel")}
                helpText={translates.get("sphereHelpText")}
                isRequired={false}
                hasError={errors.get(sphereFieldName) || errors.get(specialityFieldName)}
            >
                <FormGroup error={errors.get(sphereFieldName)}>
                    <DropDown
                        onChange={onSphereChange}
                        value={sphere}
                        options={config.get("educationSpheres")}
                        placeholder={translates.get("spherePlaceholder")}
                    />
                </FormGroup>
            </FieldLayout>
            <FieldLayout
                label={translates.get("specialityLabel")}
                helpText={translates.get("specialityHelpText")}
                isRequired={false}
                hasError={!!errors.get(specialityFieldName)}
            >
                <FormGroup error={errors.get(specialityFieldName)}>
                    <DropDown
                        onChange={onSpecialityChange}
                        value={speciality}
                        options={specialities}
                        disabled={!specialities.length}
                        placeholder={translates.get("specialityPlaceholder")}
                    />
                </FormGroup>
            </FieldLayout>
            {speciality === -1 ? (
                <FieldLayout
                    label={translates.get("otherSpecialityLabel")}
                    helpText={translates.get("otherSpecialityHelpText")}
                    isRequired={false}
                    hasError={!!errors.get(specialityFieldName)}
                    className="other-speciality"
                >
                    <FormGroup error={errors.get(specialityFieldName)}>
                        <TextInput
                            onChange={onOtherSpecialityChange}
                            value={otherSpeciality}
                        />
                    </FormGroup>
                </FieldLayout>
            ) : null}
        </div>
    );
};

SpecialityWidget.defaultProps = {
    prefix: ""
};

SpecialityWidget.propTypes = {
    item: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    translates: PropTypes.object.isRequired,
    getOnChange: PropTypes.func.isRequired,
    prefix: PropTypes.string
};

export default React.memo(SpecialityWidget);
