import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const SelectOptionsListItem = ({ onClick, option, checked }) => {
    const handleClick = () => onClick(option);

    return (
        <li
            className={classNames("select-option", { "checked": checked })}
            onClick={handleClick}
        >
            {option.label}
        </li>
    );
};

SelectOptionsListItem.propTypes = {
    option: PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired
};

export default SelectOptionsListItem;