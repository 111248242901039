import React, { useCallback } from "react";
import PropTypes from "prop-types";

const CheckboxInput = ({ name, value, label, checked, onChange }) => {
    const id = `${name}-${value}`;

    const handleChange = useCallback((e) => onChange({
        value: e.target.value,
        checked: e.target.checked
    }), [onChange]);

    return (
        <div className="input-container checkbox-container">
            <input
                type="checkbox"
                id={id}
                name={`${name}[${value}]`}
                value={value}
                checked={checked}
                onChange={handleChange}
            />
            <label
                className="color-inherit"
                htmlFor={id}
            >
                {label}
            </label>
        </div>
    );
};

CheckboxInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
};

export default CheckboxInput;