import React, { useCallback } from "react";
import PropTypes from "prop-types";

const getDecimalRegexpPart = (signs) => `\\d+\\.?\\d{0,${signs}}`;

const TextInput = ({ value, onChange, isNumeric = false, signAfterPoint = 0, placeholder = null, type, onBlur }) => {
    const handleChange = useCallback((e) => {
        if (isNumeric) {
            let regExp;
            if (signAfterPoint) {
                regExp = new RegExp(`^(\\-)?(${getDecimalRegexpPart(signAfterPoint)})?$`, "gm");
            } else {
                //eslint-disable-next-line
                regExp = /^(\-)?\d*$/gm;
            }

            if (regExp.test(e.target.value)) {
                onChange(e.target.value);
            }
        } else {
            onChange(e.target.value);
        }

    }, [onChange, isNumeric, signAfterPoint]);

    return (
        <input
            className="form-control"
            type={type}
            value={(value || "").toString()}
            onChange={handleChange}
            onBlur={onBlur}
            placeholder={placeholder}
        />
    );
};

TextInput.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    isNumeric: PropTypes.bool,
    signAfterPoint: PropTypes.number,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(["text", "tel", "email"])
};

TextInput.defaultProps = {
    type: "text"
};

export default TextInput;
