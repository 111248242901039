import loadImage from "blueimp-load-image";

const getImagePreviewFromFile = (file) => {
    return new Promise((resolve) => {
        let reader = new FileReader();
        reader.onload = (e) => {
            loadImage(
                e.target.result,
                (img) => resolve(img.src),
                { orientation: true }
            );
        };
        reader.readAsDataURL(file);
    });
};

export default getImagePreviewFromFile;