import React from "react";
import FieldLayout from "@cv-components/widgets/FieldLayout/FieldLayout";

const FormDelimiter = ({ children }) => {
    return (
        <FieldLayout
            className="form-delimiter-field-block"
            renderLabel={() => (<span className="bold-label">{children}</span>)}
        >
            <hr className="form-delimiter"/>
        </FieldLayout>
    );
};

export default FormDelimiter;