import React from "react";
import { Provider } from "react-redux";
import initializeStore from "@cv-store/initialize-store";
import { Map } from "immutable";

const store = initializeStore(Map({}));

const withStore = (Component) => (props) => {
    return (
        <Provider store={store}>
            <Component {...props}/>
        </Provider>
    );
};

export default withStore;