import React from "react";
import PropTypes from "prop-types";
import TextInput from "@cv-components/widgets/form/TextInput";
import CvField from "@cv-store/models/CvField";
import BaseSimpleTextField from "@cv-components/widgets/BaseSimpleTextField";

const SimpleTextField = ({ field, errors }) => {
    return (
        <BaseSimpleTextField
            field={field}
            errors={errors}
            renderComponent={(value, onChange, placeholder) => (
                <TextInput
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                />
            )}
        />
    );
};

SimpleTextField.propTypes = {
    field: PropTypes.instanceOf(CvField).isRequired,
    errors: PropTypes.object.isRequired,
};

export default React.memo(SimpleTextField);