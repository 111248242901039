import React from "react";
import FieldLayout from "@cv-components/widgets/FieldLayout/FieldLayout";
import PropTypes from "prop-types";

const ControlButtonsContainer = ({ children, addClass }) => {
    return (
        <FieldLayout
            label=""
            helpText={null}
            inputContainerClassName={addClass}
        >
            {children}
        </FieldLayout>
    );
};

ControlButtonsContainer.propTypes = {
    addClass: PropTypes.string,
};

export default ControlButtonsContainer;