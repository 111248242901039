import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
    FIELD_TYPE_USER_INFO__USERNAME,
    FIELD_TYPE_USER_INFO__PHOTO,
    FIELD_TYPE_USER_INFO__GENDER,
    FIELD_TYPE_USER_INFO__BIRTHDAY,
    FIELD_TYPE_USER_INFO__CHILDREN,
    FIELD_TYPE_USER_INFO__LOCATION,
    FIELD_TYPE_USER_INFO__PHONE_NUMBER,
    FIELD_TYPE_USER_INFO__EMAIL,
    FIELD_TYPE_USER_INFO__EDUCATION_LEVEL,
    FIELD_TYPE_USER_INFO__STATUS,
    FIELD_TYPE_USER_INFO__INTRO,
    FIELD_TYPE_USER_EDUCATION__EDUCATION,
    FIELD_TYPE_WORK_EXPERIENCE__EXPERIENCE,
    FIELD_TYPE_SKILLS__LANGUAGE_SKILLS,
    FIELD_TYPE_SKILLS__DRIVING_LICENSE,
    FIELD_TYPE_SKILLS__OTHER_SKILLS,
    FIELD_TYPE_SKILLS__LICENSES_AND_CERTIFICATES,
    FIELD_TYPE_FUTURE_REQUIREMENTS__CHOICE,
    FIELD_TYPE_FUTURE_REQUIREMENTS__WORK_LOCATION_AND_SCHEDULE,
    FIELD_TYPE_FUTURE_REQUIREMENTS__INFORMATION_ABOUT_SALARY,
    FIELD_TYPE_ADDITIONAL_INFORMATION__PUBLICATIONS,
    FIELD_TYPE_ADDITIONAL_INFORMATION__ORGANIZATIONS,
    FIELD_TYPE_ADDITIONAL_INFORMATION__PERSONAL_QUALITIES,
    FIELD_TYPE_ADDITIONAL_INFORMATION__INFO,
    FIELD_TYPE_SETTINGS__TITLE,
    FIELD_TYPE_SETTINGS__DESIGN
} from "@cv/constants/fieldTypes";
import { Map } from "immutable";

import UserInfoNameSurname from "@cv-components/field/UserInfoNameSurname/UserInfoNameSurname";
import UserInfoPhoto from "@cv-components/field/UserInfoPhoto/UserInfoPhoto";
import UserInfoGender from "@cv-components/field/UserInfoGender/UserInfoGender";
import UserInfoBirthday from "@cv-components/field/UserInfoBirthday/UserInfoBirthday";
import UserInfoChildren from "@cv-components/field/UserInfoChildren/UserInfoChildren";
import UserInfoLocation from "@cv-components/field/UserInfoLocation/UserInfoLocation";
import SimpleTextField from "@cv-components/field/SimpleTextField/SimpleTextField";
import SimpleTextareaField from "@cv-components/field/SimpleTextareaField/SimpleTextareaField";
import SimpleDropdownField from "@cv-components/field/SimpleDropdownField/SimpleDropdownField";

import "@cv-components/field/CvFormField.scss";
import UserEducationEducation from "@cv-components/field/UserEducationEducation/UserEducationEducation";
import WorkExperienceExperience from "@cv-components/field/WorkExperienceExperience/WorkExperienceExperience";
import SkillsLanguageSkills from "@cv-components/field/SkillsLanguageSkills/SkillsLanguageSkills";
import SkillsDrivingLicense from "@cv-components/field/SkillsDrivingLicense/SkillsDrivingLicense";
import SkillsOtherSkills from "@cv-components/field/SkillsOtherSkills/SkillsOtherSkills";
import SkillsLicensesAndCertificates from "@cv-components/field/SkillsLicensesAndCertificates/SkillsLicensesAndCertificates";
import FutureWorkRequirementsChoice
    from "@cv-components/field/FutureWorkRequirementsChoice/FutureWorkRequirementsChoice";
import FutureWorkRequirementsWorkLocationAndSchedule
    from "@cv-components/field/FutureWorkRequirementsWorkLocationAndSchedule/FutureWorkRequirementsWorkLocationAndSchedule";
import FutureWorkRequirementsInformationAboutSalary
    from "@cv-components/field/FutureWorkRequirementsInformationAboutSalary/FutureWorkRequirementsInformationAboutSalary";
import AdditionalInformationPublications
    from "@cv-components/field/AdditionalInformationPublications/AdditionalInformationPublications";
import AdditionalInformationOrganizations
    from "@cv-components/field/AdditionalInformationOrganizations/AdditionalInformationOrganizations";
import AdditionalInformationPersonalQualities
    from "@cv-components/field/AdditionalInformationPersonalQualities/AdditionalInformationPersonalQualities";
import Container from "@cv-components/widgets/Container/Container";
import FormDelimiter from "@cv-components/widgets/FormDelimiter/FormDelimiter";

/**
 * @param fieldType
 * @param fields
 * @returns {*}
 * @constructor
 */
const CvFormField = ({ fieldType }) => {
    /** @typeof {CvField} */
    const field = useSelector((state) => state.getIn(["formData", "fields", fieldType]));
    const errors = useSelector((state) => {
        const errors = state.getIn(["formData", "errors", fieldType]);
        return errors ? errors : Map({});
    });

    switch (field.type) {
    case FIELD_TYPE_USER_INFO__USERNAME: {
        return (
            <UserInfoNameSurname
                key={field.type}
                field={field}
                errors={errors}
            />
        );
    }
    case FIELD_TYPE_USER_INFO__PHOTO: {
        return (
            <UserInfoPhoto
                key={field.type}
                field={field}
                errors={errors}
            />
        );
    }
    case FIELD_TYPE_USER_INFO__GENDER: {
        return (
            <UserInfoGender
                key={field.type}
                field={field}
                errors={errors}
            />
        );
    }
    case FIELD_TYPE_USER_INFO__BIRTHDAY: {
        return (
            <UserInfoBirthday
                key={field.type}
                field={field}
                errors={errors}
            />
        );
    }
    case FIELD_TYPE_USER_INFO__CHILDREN: {
        return (
            <UserInfoChildren
                key={field.type}
                field={field}
                errors={errors}
            />
        );
    }
    case FIELD_TYPE_USER_INFO__LOCATION: {
        return (
            <UserInfoLocation
                key={field.type}
                field={field}
                errors={errors}
            />
        );
    }
    case FIELD_TYPE_USER_INFO__PHONE_NUMBER:
    case FIELD_TYPE_SETTINGS__TITLE:
    case FIELD_TYPE_USER_INFO__EMAIL: {
        return (
            <SimpleTextField
                key={field.type}
                field={field}
                errors={errors}
            />
        );
    }
    case FIELD_TYPE_USER_INFO__INTRO: {
        return (
            <SimpleTextareaField
                key={field.type}
                field={field}
                errors={errors}
            />
        );
    }
    case FIELD_TYPE_USER_INFO__EDUCATION_LEVEL:
    case FIELD_TYPE_USER_INFO__STATUS:
    case FIELD_TYPE_SETTINGS__DESIGN: {
        return (
            <SimpleDropdownField
                key={field.type}
                field={field}
                errors={errors}
            />
        );
    }
    case FIELD_TYPE_USER_EDUCATION__EDUCATION: {
        return (
            <UserEducationEducation
                key={field.type}
                field={field}
                errors={errors}
            />
        );
    }
    case FIELD_TYPE_WORK_EXPERIENCE__EXPERIENCE: {
        return (
            <WorkExperienceExperience
                key={field.type}
                field={field}
                errors={errors}
            />
        );
    }
    case FIELD_TYPE_SKILLS__LANGUAGE_SKILLS: {
        return (
            <SkillsLanguageSkills
                key={field.type}
                field={field}
                errors={errors}
            />
        );
    }
    case FIELD_TYPE_SKILLS__DRIVING_LICENSE: {
        return (
            <SkillsDrivingLicense
                key={field.type}
                field={field}
                errors={errors}
            />
        );
    }
    case FIELD_TYPE_SKILLS__OTHER_SKILLS: {
        return (
            <SkillsOtherSkills
                key={field.type}
                field={field}
                errors={errors}
            />
        );
    }
    case FIELD_TYPE_SKILLS__LICENSES_AND_CERTIFICATES: {
        return (
            <SkillsLicensesAndCertificates
                key={field.type}
                field={field}
                errors={errors}
            />
        );
    }
    case FIELD_TYPE_FUTURE_REQUIREMENTS__CHOICE: {
        return (
            <FutureWorkRequirementsChoice
                key={field.type}
                field={field}
                errors={errors}
            />
        );
    }
    case FIELD_TYPE_FUTURE_REQUIREMENTS__WORK_LOCATION_AND_SCHEDULE: {
        return (
            <FutureWorkRequirementsWorkLocationAndSchedule
                key={field.type}
                field={field}
                errors={errors}
            />
        );
    }
    case FIELD_TYPE_FUTURE_REQUIREMENTS__INFORMATION_ABOUT_SALARY: {
        return (
            <FutureWorkRequirementsInformationAboutSalary
                key={field.type}
                field={field}
                errors={errors}
            />
        );
    }
    case FIELD_TYPE_ADDITIONAL_INFORMATION__PUBLICATIONS: {
        return (
            <AdditionalInformationPublications
                key={field.type}
                field={field}
                errors={errors}
            />
        );
    }
    case FIELD_TYPE_ADDITIONAL_INFORMATION__ORGANIZATIONS: {
        return (
            <AdditionalInformationOrganizations
                key={field.type}
                field={field}
                errors={errors}
            />
        );
    }
    case FIELD_TYPE_ADDITIONAL_INFORMATION__PERSONAL_QUALITIES: {
        return (
            <AdditionalInformationPersonalQualities
                key={field.type}
                field={field}
                errors={errors}
            />
        );
    }
    case FIELD_TYPE_ADDITIONAL_INFORMATION__INFO: {
        return (
            <Container addClass="border-none">
                <FormDelimiter>{field.translates.get("title")}</FormDelimiter>
                <SimpleTextareaField
                    key={field.type}
                    field={field}
                    errors={errors}
                    showLabel={false}
                />
            </Container>
        );
    }
    default: {
        console.error("Unknown field type "+field.type);
        return null;
    }
    }
};

CvFormField.propTypes = {
    fieldType: PropTypes.string.isRequired
};

export default CvFormField;