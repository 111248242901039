import React, { useCallback } from "react";
import { Map } from "immutable";
import CertificateOrLicenseItem from "@cv-components/field/SkillsLicensesAndCertificates/CertificateOrLicenseItem";
import SimpleMultiItemsField from "@cv-components/field/SimpleMultiItemsField";

const defaultItem = {
    label: "",
    company: "",
    year: "",
    additionalInfo: "",
};

const SkillsLicensesAndCertificates = ({ field, errors }) => {
    const translates = field.translates;

    const renderItems = useCallback((items, handleRemoveItem, handleItemChange, handleAddItem) => {
        return items.map((item, index) => {
            return (
                <CertificateOrLicenseItem
                    key={item.get("id")}
                    customSkillItem={item}
                    handleRemove={handleRemoveItem}
                    handleAddItem={handleAddItem}
                    handleChange={handleItemChange}

                    showAddBtn={items.size === index+1}

                    translates={translates}
                    errors={errors.getIn(["items", index.toString()]) || Map({})}
                />
            );
        });
    }, [translates, errors]);

    return (
        <SimpleMultiItemsField
            field={field}
            errors={errors}
            defaultItem={defaultItem}
            renderItems={renderItems}
            addClass="border-none"
        />
    );
};

export default SkillsLicensesAndCertificates;