import React, { useCallback } from "react";
import PropTypes from "prop-types";
import SimpleField from "@cv-components/widgets/form/SimpleField";
import useGetOnChange from "@cv/hooks/useGetOnChange";
import TextareaInput from "@cv-components/widgets/form/TextareaInput";
import Container from "@cv-components/widgets/Container/Container";
import FormDelimiter from "@cv-components/widgets/FormDelimiter/FormDelimiter";
import DropDown from "@cv-components/widgets/form/DropDown";
import WorkPositionWidget from "@cv-components/widgets/form/WorkPositionWidget";

const FutureWorkRequirementsItem = ({
    item,
    handleChange,
    translates,
    errors,
    config
}) => {
    const getOnChange = useGetOnChange(handleChange, item);

    const onSphereChange = useCallback((val) => {
        handleChange(item.set("sphere", val).set("position", null));
    }, [handleChange, item]);

    const onAdditionalInfoChange = getOnChange("additionalInfo");

    return (
        <Container>
            <FormDelimiter>{translates.get(`title-${item.get("id")}`)}</FormDelimiter>
            <SimpleField fieldName="sphere" translates={translates} errors={errors} isRequired={false}>
                <DropDown
                    options={config.get("workSphereOptions")}
                    onChange={onSphereChange}
                    value={item.get("sphere")}
                    placeholder={translates.get("spherePlaceholder")}
                />
            </SimpleField>
            <WorkPositionWidget
                handleChange={handleChange}
                item={item}
                translates={translates}
                errors={errors}
                config={config}
            />
            <SimpleField fieldName="additionalInfo" errors={errors} translates={translates} isRequired={false}>
                <TextareaInput
                    value={item.get("additionalInfo")}
                    onChange={onAdditionalInfoChange}
                    placeholder={translates.get("additionalInfoPlaceholder")}
                />
            </SimpleField>
        </Container>
    );
};

FutureWorkRequirementsItem.propTypes = {
    item: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    config: PropTypes.object.isRequired,
    translates: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

export default FutureWorkRequirementsItem;