import React, { useCallback, useEffect } from "react";
import SimpleField from "@cv-components/widgets/form/SimpleField";
import DropDown from "@cv-components/widgets/form/DropDown";
import FieldLayout from "@cv-components/widgets/FieldLayout/FieldLayout";
import FormGroup from "@cv-components/widgets/form/FormGroup";
import useGetOnChange from "@cv/hooks/useGetOnChange";
import RemoveItemBtn from "@cv-components/widgets/RemoveItemBtn/RemoveItemBtn";
import "@cv-components/field/SkillsLanguageSkills/KnownLanguage.scss";

const KnownLanguageSelectedLang = ({ knownLangItem, langOptions, translates, errors, config, getOnChange, onRemove }) => {
    const language = knownLangItem.get("language");
    const langName = langOptions.find((langOption) => langOption.value === language)?.label;

    const onUnderstandingChange = getOnChange("understanding");
    const onTalkingChange = getOnChange("talking");
    const onWritingChange = getOnChange("writing");

    useEffect(() => {
        if (!knownLangItem.get("understanding")) {
            onUnderstandingChange(config.get("evaluationOptions")[0].value);
        }
    }, [onUnderstandingChange]);//eslint-disable-line

    useEffect(() => {
        if (!knownLangItem.get("talking")) {
            onTalkingChange(config.get("evaluationOptions")[0].value);
        }
    }, [onTalkingChange]);//eslint-disable-line

    useEffect(() => {
        if (!knownLangItem.get("writing")) {
            onWritingChange(config.get("evaluationOptions")[0].value);
        }
    }, [onWritingChange]);//eslint-disable-line

    let allErrors = [];

    if (errors.get("understanding")) {
        allErrors = allErrors.concat(errors.get("understanding"));
    }

    if (errors.get("talking")) {
        allErrors = allErrors.concat(errors.get("understanding"));
    }

    if (errors.get("writing")) {
        allErrors = allErrors.concat(errors.get("understanding"));
    }

    return (
        <FieldLayout
            label={langName+":"}
            helpText={translates.get("languageKnowledgeHelpText")}
            isRequired={false}
            hasError={!!allErrors.length}
        >
            <FormGroup addClass="known-language-level d-flex" error={allErrors}>
                <DropDown
                    value={knownLangItem.get("understanding")}
                    options={config.get("evaluationOptions")}
                    onChange={onUnderstandingChange}
                    placeholder={translates.get("understandingPlaceholder")}
                />
                <DropDown
                    value={knownLangItem.get("talking")}
                    options={config.get("evaluationOptions")}
                    onChange={onTalkingChange}
                    placeholder={translates.get("talkingPlaceholder")}
                />
                <DropDown
                    value={knownLangItem.get("writing")}
                    options={config.get("evaluationOptions")}
                    onChange={onWritingChange}
                    placeholder={translates.get("writingPlaceholder")}
                />
                <RemoveItemBtn addClass="known-language-remove" onClick={onRemove}/>
            </FormGroup>
        </FieldLayout>
    );
};

const KnownLanguage = ({ knownLangItem, translates, errors, config, handleRemove, handleChange, langOptions }) => {
    const itemId = knownLangItem.get("id");
    const onRemove = useCallback(() => handleRemove(itemId), [handleRemove, itemId]);
    const getOnChange = useGetOnChange(handleChange, knownLangItem);

    const onLanguageChange = getOnChange("language");

    const language = knownLangItem.get("language");

    return (
        <div className="known-language">
            {language ? (
                <KnownLanguageSelectedLang
                    knownLangItem={knownLangItem}
                    translates={translates}
                    errors={errors}
                    config={config}
                    onRemove={onRemove}
                    getOnChange={getOnChange}
                    langOptions={langOptions}
                />
            ) : (
                <SimpleField fieldName="language" errors={errors} translates={translates} isRequired={true}>
                    <DropDown
                        value={knownLangItem.get("language")}
                        options={langOptions}
                        onChange={onLanguageChange}
                        placeholder={translates.get("languagePlaceholder")}
                    />
                </SimpleField>
            )}
        </div>
    );
};

export default KnownLanguage;