import React, { useCallback, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Modal, { CloseButton } from "../../../../../widgets/Modal/Modal";
import ReactCrop from "react-image-crop";
import "react-image-crop/lib/ReactCrop.scss";

const CropModal = ({ imageUrl, closeModal, onReject, onCropped, btnText }) => {
    const imgRef = useRef(null);
    const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 1 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const [res, setRes] = useState(null);

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        setRes({
            x: crop.x * scaleX,
            y: crop.y * scaleY,
            x1: (crop.x + crop.width) * scaleX,
            y1: (crop.y + crop.height) * scaleY,
        });
    }, [completedCrop, setRes]);

    const onConfirmed = useCallback(() => {
        onCropped(res);
        closeModal();
    }, [res, closeModal, onCropped]);

    return (
        <Modal onClose={onReject}>
            <ReactCrop
                src={imageUrl}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => setCompletedCrop(c)}
            />
            <CloseButton className="btn-cv-photo-confirm btn btn-info" onClick={onConfirmed}>{btnText}</CloseButton>
        </Modal>
    );
};

CropModal.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
    onCropped: PropTypes.func.isRequired,
    btnText: PropTypes.string
};

export default CropModal;