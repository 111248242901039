import React from "react";
import PropTypes from "prop-types";
import RadioInput from "@cv-components/widgets/form/RadioInput";

const RadioList = ({ name, options, value, onChange }) => {
    return (
        <div className="d-flex flex-wrap">
            {options.map((option) => (
                <RadioInput
                    key={`name-${option.value}`}
                    label={option.label}
                    name={name}
                    value={option.value}
                    checked={option.value === value}
                    onChange={onChange}
                />
            ))}
        </div>
    );
};

RadioList.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.any
};

export default RadioList;