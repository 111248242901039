import React from "react";
import { Map } from "immutable";
import Container from "@cv-components/widgets/Container/Container";
import FormDelimiter from "@cv-components/widgets/FormDelimiter/FormDelimiter";
import SimpleField from "@cv-components/widgets/form/SimpleField";
import DropDown from "@cv-components/widgets/form/DropDown";
import useFieldChange from "@cv/hooks/useFieldChange";
import useMultiItems from "@cv/hooks/useMultiItems";
import AddItemBtn from "@cv-components/widgets/RemoveItemBtn/AddItemBtn";
import ControlButtonsContainer from "@cv-components/widgets/RemoveItemBtn/ControlButtonsContainer";
import KnownLanguage from "@cv-components/field/SkillsLanguageSkills/KnownLanguage";
import FieldLayout from "@cv-components/widgets/FieldLayout/FieldLayout";

const defaultItem = {
    language: null,
    understanding: null,
    talking: null,
    writing: null,
};

const SkillsLanguageSkills = ({ field, errors }) => {
    const nativeLangChange = useFieldChange(field, (inputVal, value) => value.set("nativeLanguage", inputVal));
    const translates = field.translates;
    const value = field.value;
    const config = field.config;

    const { addItem, removeItem, changeItem } = useMultiItems(undefined, defaultItem);

    const handleAddItem = useFieldChange(field, addItem);
    const handleRemoveItem = useFieldChange(field, removeItem);
    const handleItemChange = useFieldChange(field, changeItem);

    return (
        <Container>
            <FormDelimiter>{translates.get("title")}</FormDelimiter>
            <SimpleField fieldName="nativeLanguage" errors={errors} translates={translates} isRequired={true}>
                <DropDown
                    value={value.get("nativeLanguage")}
                    options={config.get("languageOptions")}
                    onChange={nativeLangChange}
                    placeholder={translates.get("nativeLanguagePlaceholder")}
                />
            </SimpleField>
            {value.get("items").filter((item) => !!item.get("language")).size ? (
                <FieldLayout label="">
                    <div className="d-flex known-language-level-title">
                        <span className="language-level-title">{translates.get("understandingLabel")}</span>
                        <span className="language-level-title">{translates.get("talkingLabel")}</span>
                        <span className="language-level-title">{translates.get("writingLabel")}</span>
                    </div>
                </FieldLayout>
            ) : null}
            <div>
                {value.get("items").map((item, index) => {
                    const allLangOptions = config.get("languageOptions");
                    const prevValues = value.get("items").slice(0, index).map((item) => item.get("language"));
                    const langOptions = allLangOptions.filter((lang) => {
                        const index = prevValues.findIndex((prevVal) => prevVal === lang.value);

                        return index === -1 && lang.value !== value.get("nativeLanguage");
                    });

                    return (
                        <KnownLanguage
                            key={item.get("id")}
                            knownLangItem={item}
                            handleRemove={handleRemoveItem}
                            handleChange={handleItemChange}
                            translates={translates}
                            config={config}
                            errors={errors.getIn(["items", index.toString()]) || Map({})}
                            langOptions={langOptions}
                        />
                    );
                })}
            </div>
            {
                (config.get("languageOptions").length > (value.get("items").size + 1))
                    && (value.get("items").findIndex((item) => !item.get("language")) === -1)
                    ? (
                        <ControlButtonsContainer>
                            <AddItemBtn type="button" onClick={handleAddItem}>
                                {field.translates.get("addBtnText")}
                            </AddItemBtn>
                        </ControlButtonsContainer>
                    )
                    : null}
        </Container>
    );
};

export default SkillsLanguageSkills;