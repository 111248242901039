import React from "react";
import PropTypes from "prop-types";
import FieldLayout from "@cv-components/widgets/FieldLayout/FieldLayout";
import CvField from "@cv-store/models/CvField";
import useFieldChange from "@cv/hooks/useFieldChange";
import FormGroup from "@cv-components/widgets/form/FormGroup";
import FileInput from "@cv-components/widgets/form/FileInput/FileInput";
import "@cv-components/field/UserInfoPhoto/UserInfoPhoto.scss";

const UserInfoPhoto = ({ field, errors }) => {
    const valueChange = useFieldChange(field, (inputVal, value) => value.set("imageId", inputVal));

    return (
        <FieldLayout
            label={field.translates.get("label")}
            helpText={field.translates.get("helpText")}
            isRequired={field.isRequired}
            hasError={!!errors.size}
        >
            <FormGroup error={errors.get("imageId")}>
                <FileInput
                    onChange={valueChange}
                    btnText={field.translates.get("selectFileBtnText")}
                    cropperSelectBtnText={field.translates.get("confirmCropBtnText")}
                    value={field.value.get("imageId")}
                    imageUrl={field.value.get("imageUrl") || ""}
                    uploadUrl={field.config.get("uploadImageUrl")}
                />
            </FormGroup>
        </FieldLayout>
    );
};

UserInfoPhoto.propTypes = {
    field: PropTypes.instanceOf(CvField).isRequired,
    errors: PropTypes.object.isRequired,
};

export default React.memo(UserInfoPhoto);