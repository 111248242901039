import React, { useCallback } from "react";
import PropTypes from "prop-types";
import FormGroup from "@cv-components/widgets/form/FormGroup";
import TextInput from "@cv-components/widgets/form/TextInput";
import FieldLayout from "@cv-components/widgets/FieldLayout/FieldLayout";
import { modifierToNumber } from "@cv/hooks/useGetOnChange";
import * as dayjs from "dayjs";

const YearsPeriodBlock = ({ errors, translates, item, getOnChange }) => {
    const yearFromChange = useCallback(getOnChange("yearFrom", modifierToNumber), [getOnChange]);
    const yearToChange = useCallback(getOnChange("yearTo", modifierToNumber), [getOnChange]);

    const yearToValue = item.get("yearTo");
    const yearFromValue = item.get("yearFrom");

    const yearFromBlur = useCallback((e) => {
        const yearFrom = e.target.value;
        const currentYear = dayjs().year();

        if (yearFrom < 1900) {
            yearFromChange(1900);
        }

        if (yearFrom > currentYear) {
            yearFromChange(currentYear);
        }

        if (yearFrom > yearToValue) {
            yearToChange('');
        }
    }, [yearFromChange, yearToChange, yearToValue]);

    const yearToBlur = useCallback((e) => {
        const yearTo = e.target.value;
        const currentYear = dayjs().year();
        if(yearTo !== '') {
            if (yearTo < 1900) {
                yearToChange(1900);
            }

            if (yearTo > currentYear) {
                yearToChange(currentYear);
            }

            if (yearTo < yearFromValue) {
                yearToChange(yearFromValue);
            }
        }
    }, [yearToChange, yearFromValue]);

    let periodErrors = [];
    if (errors.get("yearFrom")) {
        periodErrors = periodErrors.concat(errors.get("yearFrom"));
    }

    if (errors.get("yearTo")) {
        periodErrors = periodErrors.concat(errors.get("yearTo"));
    }

    return (
        <FieldLayout
            label={translates.get("yearsPeriodLabel")}
            helpText={translates.get("yearsPeriodHelpText")}
            isRequired={true}
            hasError={!!periodErrors.length}
        >
            <FormGroup error={periodErrors}>
                <div className="d-flex years-period-block">
                    <TextInput
                        onChange={yearFromChange}
                        onBlur={yearFromBlur}
                        value={yearFromValue}
                        isNumeric={true}
                        signAfterPoint={0}
                    />
                    <span className="liner-years-period">-</span>
                    <TextInput
                        onChange={yearToChange}
                        onBlur={yearToBlur}
                        value={yearToValue}
                        isNumeric={true}
                        signAfterPoint={0}
                    />
                </div>
            </FormGroup>
        </FieldLayout>
    );
};

YearsPeriodBlock.propTypes = {
    item: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    translates: PropTypes.object.isRequired,
    getOnChange: PropTypes.func.isRequired,
};

export default YearsPeriodBlock;