import { Record, Map, fromJS } from "immutable";

let defaultProps = {
    type: null,
    block: null,
    config: null,
    isRequired: null,
    order: null,
    translates: {},
    value: {}
};

export default class CvField extends Record(defaultProps) {
    constructor(data) {
        const props = { ...data };

        if (Array.isArray(props.config) && !props.config.length) {
            props.config = {};
        }

        super(props);
    }

    static fromObject(data) {
        data.value = fromJS(data.value ? data.value : {});
        data.translates = Map(data.translates);
        data.config = Map(data.config);

        return new CvField(data);
    }
}
