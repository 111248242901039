import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const SelectContainer = ({ renderBody, maxHeight, positionClass, setPositionClass, getParentRect }) => {
    const [stateMaxHeight, setStateMaxHeight] = useState(maxHeight);
    const containerRef = useRef();

    useEffect(() => {
        if (containerRef.current) {
            const bordersWidth = 1 + 1;

            const rect = containerRef.current.getBoundingClientRect();
            const toBot = window.innerHeight - (rect.top + rect.height);
            const toTop = rect.top - rect.height;

            const max = toBot > toTop ?
                (window.innerHeight - rect.top - bordersWidth)
                : (rect.top - getParentRect().height - bordersWidth);
            setStateMaxHeight(max);
            containerRef.current.scrollTop = 0;
            setPositionClass(toBot > toTop ? "bottom" : "top");
        }

    }, [containerRef]);// eslint-disable-line

    return (
        <div
            className={classNames("select-values-container", positionClass) }
            ref={containerRef}
            style={{ maxHeight: Math.min(stateMaxHeight, maxHeight) }}
        >
            {renderBody(maxHeight)}
        </div>
    );
};

SelectContainer.propTypes = {
    renderBody: PropTypes.func.isRequired,
    maxHeight: PropTypes.number.isRequired,
    positionClass: PropTypes.string.isRequired,
    setPositionClass: PropTypes.func.isRequired,
    getParentRect: PropTypes.func.isRequired,
};

export default SelectContainer;