import React, { useCallback, useEffect, useState } from "react";
import withStore from "@cv-store/with-store";
import { useDispatch, useSelector } from "react-redux";
import { setFormData, setFieldErrors, setDraftValues, setTranslates } from "@cv-store/actions";
import CvFormView from "@cv/CvFormView";
import sendCV from "@cv/utils/sendCV";
import useDraft from "@cv/hooks/useDraft";
import usePrevious from "../hooks/usePrevious";

const CvForm = ({
    action,
    form,
    token,
    creation,
    draftaction,
    deletedraftaction,
    draft,
    translates,
    showdraftconfirm,
    creationurl,
    previewurl
}) => {
    const dispatch = useDispatch();

    const showDraftConfirm = Number.parseInt(showdraftconfirm);

    useEffect(() => {
        dispatch(setFormData(JSON.parse(form)));
        dispatch(setTranslates(JSON.parse(translates)));
        window.csrfToken = token;

        window.saveDraftTimer = null;
    }, []);// eslint-disable-line

    const setErrors = (errors) => dispatch(setFieldErrors(errors));

    /** @typeof {List} */
    const blocks = useSelector((state) => state.getIn(["formData", "blocks"]));

    /** @typeof {Map} */
    const fields = useSelector((state) => state.getIn(["formData", "fields"]));

    /** @typeof {Map} */
    const parsedTranslates = useSelector((state) => state.getIn(["formData", "translates"]));

    /** @typeof {Map} */
    const changesCounter = useSelector((state) => state.getIn(["formData", "changesCounter"]));

    const prevChangesCounter = usePrevious(changesCounter);

    const getFormData = () => {
        let result = {};
        fields.entrySeq().forEach((e) => {
            result[e[0]] = e[1].value.toJS();
        });

        return result;
    };

    const [getDraft, saveDraft, deleteDraft] = useDraft(+creation, draftaction, deletedraftaction, draft);

    const loadDraft = (values) => {
        return dispatch(setDraftValues(values));
    };

    const [draftDialogHandled, setDraftDialogHandled] = useState(false);

    useEffect(() => {
        if (fields && !draftDialogHandled) {
            const draft = getDraft();
            setDraftDialogHandled(true);

            if (draft) {
                if (showDraftConfirm && confirm(parsedTranslates.get("useDraftConfirmation"))) {// eslint-disable-line
                    loadDraft(draft.data);
                } else {
                    deleteDraft();
                }
            }
        }
    }, [fields, draftDialogHandled, setDraftDialogHandled, getDraft, deleteDraft]);// eslint-disable-line

    useEffect(() => {
        if (fields && draftDialogHandled && prevChangesCounter !== changesCounter) {
            clearTimeout(window.saveDraftTimer);
            window.saveDraftTimer = setTimeout(() => {
                saveDraft(getFormData());
            }, 500);
        }
    }, [fields]);// eslint-disable-line

    const handleFormSubmit = useCallback((e, redirectToCreation =  false) => {
        e.preventDefault();

        sendCV(action, getFormData())
            .then((response) => {
                setTimeout(() => {
                    deleteDraft();
                    if (redirectToCreation) {
                        window.location.href = creationurl;
                    } else {
                        window.location.href = response.data;
                    }
                }, 100);
            })
            .catch((err) => {
                const response = err.response;
                if (response.status === 422) {
                    setErrors(response.data);
                }

                let firstBlockWithError = document.getElementsByClassName('has-error')[0];
                firstBlockWithError.scrollIntoView({ behavior: 'smooth' });
            });

    }, [action, deleteDraft, fields]);//eslint-disable-line

    const handleFormSubmitAndNew = useCallback((e) => {
        handleFormSubmit(e, true);
    }, [handleFormSubmit]);

    if (!fields) {
        return (
            <div className="text-center spinner-block">
                <img src="/resource/img/icons/load_animation.gif" alt="" className="load-animation"/>
            </div>
        );
    }

    const formData = getFormData();

    return (
        <div>
            <CvFormView
                handleFormSubmit={handleFormSubmit}
                handleFormSubmitAndNew={handleFormSubmitAndNew}
                showCreateAndNew={true}
                blocks={blocks}
                translates={parsedTranslates}
                previewUrl={previewurl}
                previewData={JSON.stringify(formData)}
            />
        </div>
    );
};

CvForm.defaultProps = {
    showdraftconfirm: "1"
};

export default withStore(CvForm);