import React, { useCallback } from "react";
import PropTypes from "prop-types";
import FormGroup from "@cv-components/widgets/form/FormGroup";
import FieldLayout from "@cv-components/widgets/FieldLayout/FieldLayout";
import TextInput from "@cv-components/widgets/form/TextInput";
import DropDown from "@cv-components/widgets/form/DropDown";
import _ from "lodash";
import TextareaInput from "@cv-components/widgets/form/TextareaInput";
import SimpleField from "@cv-components/widgets/form/SimpleField";
import useYearInputValidation from "@cv/hooks/useYearInputValidation";

const _PeriodWidget = ({ item, config, translates, errors, getOnChange }) => {
    const onPeriodCountChange = useCallback(getOnChange("periodCount"), [getOnChange]);
    const onPeriodUnitChange = useCallback(getOnChange("periodUnit"), [getOnChange]);

    let allErrors = [];
    if (errors.get("periodCount")) {
        allErrors = allErrors.concat(errors.get("periodCount"));
    }

    if (errors.get("periodUnit")) {
        allErrors = allErrors.concat(errors.get("periodUnit"));
    }

    const periodCountOptions = _.range(1, config.get("maxPeriodCount")+1).map((v) => ({ label: v, value: v }));

    return (
        <FieldLayout
            label={translates.get("periodLabel")}
            helpText={translates.get("periodHelpText")}
            isRequired={true}
            hasError={!!allErrors.length}
            className="period"
        >
            <FormGroup addClass="period-container-wrapper" error={allErrors}>
                <DropDown
                    onChange={onPeriodCountChange}
                    options={periodCountOptions}
                    placeholder={translates.get("periodCountPlaceholder")}
                    value={item.get("periodCount")}
                />
                <DropDown
                    onChange={onPeriodUnitChange}
                    options={config.get("periodUnitOptions")}
                    placeholder={translates.get("periodUnitPlaceholder")}
                    value={item.get("periodUnit")}
                />
            </FormGroup>
        </FieldLayout>
    );
};

const PeriodWidget = React.memo(_PeriodWidget);

const AdditionalEducationItem = ({ item, config, translates, errors, getOnChange }) => {
    const onTrainingCompanyChange = useCallback(getOnChange("trainingCompany"), [getOnChange]);
    const onSubjectCompanyChange = useCallback(getOnChange("subject"), [getOnChange]);
    const onYearChange = useCallback(getOnChange("year"), [getOnChange]);
    const onAdditionalInfoChange = useCallback(getOnChange("additionalInformation"), [getOnChange]);

    const handleYearBlur = useYearInputValidation(onYearChange);

    return (
        <div>
            <FieldLayout
                label={translates.get("trainingCompanyLabel")}
                helpText={translates.get("trainingCompanyHelpText")}
                isRequired={true}
                hasError={!!errors.get("trainingCompany")}
            >
                <FormGroup error={errors.get("trainingCompany")}>
                    <TextInput
                        value={item.get("trainingCompany")}
                        placeholder={translates.get("trainingCompanyPlaceholder")}
                        onChange={onTrainingCompanyChange}
                    />
                </FormGroup>
            </FieldLayout>
            <FieldLayout
                label={translates.get("subjectLabel")}
                helpText={translates.get("subjectHelpText")}
                isRequired={false}
                hasError={!!errors.get("subject")}
            >
                <TextInput
                    value={item.get("subject")}
                    placeholder={translates.get("subjectPlaceholder")}
                    onChange={onSubjectCompanyChange}
                />
            </FieldLayout>
            <FieldLayout
                label={translates.get("yearLabel")}
                helpText={translates.get("yearHelpText")}
                isRequired={false}
                hasError={!!errors.get("year")}
            >
                <FormGroup error={errors.get("year")}>
                    <TextInput
                        value={item.get("year")}
                        placeholder={translates.get("yearPlaceholder")}
                        onChange={onYearChange}
                        onBlur={handleYearBlur}
                    />
                </FormGroup>
            </FieldLayout>
            <PeriodWidget
                getOnChange={getOnChange}
                item={item}
                translates={translates}
                config={config}
                errors={errors}
            />
            <SimpleField fieldName="additionalInformation" translates={translates} errors={errors}>
                <TextareaInput
                    value={item.get("additionalInformation")}
                    onChange={onAdditionalInfoChange}
                    placeholder={translates.get("additionalInformationPlaceholder")}
                />
            </SimpleField>
        </div>
    );
};

AdditionalEducationItem.propTypes = {
    item: PropTypes.object.isRequired,
    getOnChange: PropTypes.func.isRequired,
    config: PropTypes.object.isRequired,
    translates: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

export default AdditionalEducationItem;